import { ServiceData } from "data";
import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { ServiceType } from "types/service.type";


type ConfigServicesType = {
    key: ServiceType;
    title: string;
    description: string;
};

const SectionService: React.FC = () => {
    const services = ServiceData as ConfigServicesType[];

    return (<section className="bg-smoke space" id="service-sec">
        <div className="container">
            <div className="row justify-content-lg-between justify-content-center align-items-center">
                <div className="col-lg-6">
                    <div className="title-area text-lg-start">
                        <span className="sub-title">OUR SERVICE </span>
                        <h2 className="sec-title text-capitalize">
                            Why choose to use our service RV Limousine
                        </h2>
                    </div>
                </div>

            </div>
            <div className="row th-carousel" id="serviceSlide2" data-slide-show="3" data-lg-slide-show="2" data-md-slide-show="2">

                {services && services.map(service => <div key={service.key} className="col-md-6 col-lg-4">
                    <div className="service-card wow fadeInUp background-image" style={{ "backgroundImage": "url('/theme/assets/img/service/service_shape.jpg')" }}>
                        <div className="service-card_content">
                            <div className="service-card_icon">
                                <i className="fa-thin fa-business-time"></i>
                            </div>
                            <h3 className="service-card_title">
                                <Link to={`/services/${service.key}`}>{service.title}</Link>
                            </h3>
                            <p className="service-card_text">{service.description}</p>
                            <Link className="th-btn" to={`/services/${service.key}`}>CLICK TO BOOK</Link>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </section>)
}

export default memo(SectionService);