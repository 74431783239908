import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil } from "@standard/utils";
import { useForm } from 'react-hook-form';
import { MemberAPI } from "api";
import DataForm from "components/DataForm";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import registerSchema from "./register.schema";

const defaultValues = {
    name: "",
    telephone: "",
    email: "",
    password: "",
    confirmPassword: "",
    weChatId: "",
    whatAppId: "",
    lineId: "",
};

export default () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const reactHookForm = useForm({ defaultValues, resolver: joiResolver(registerSchema) });
    const [errors, setErrors] = useState("");
    const { setTitle } = useContext(ThemeContext);

    useEffect(() => {
        setTitle("Register");
    }, [setTitle])

    const fields = [
        { name: "name", label: "Your Name", type: "text", col: 6, icon: <i className="fal fa-user" /> },
        { name: "telephone", label: "Phone Number", type: "telephone", col: 6, icon: <i className="fal fa-phone" /> },
        { name: "email", label: "Email", type: "email", col: 12, icon: <i className="fal fa-envelope" /> },
        { name: "password", label: "Password", type: "password", col: 6, icon: <i className="fal fa-key" /> },
        { name: "confirmPassword", label: "Confirm Password", type: "password", col: 6, icon: <i className="fal fa-key" /> },
        { name: "weChatId", label: "We Chat", type: "text", col: 12, icon: <i className="fa-brands fa-weixin" /> },
        { name: "whatAppId", label: "What App", type: "text", col: 12, icon: <i className="fa-brands fa-whatsapp" /> },
        { name: "lineId", label: "Line", type: "text", col: 12, icon: <i className="fa-brands fa-line" /> },
    ];

    const onSubmit = (inputData: any) => {
        AlertUtil.confirm("Do you want to register ?").then(async (res: boolean) => {
            if (res === false) return;

            setIsLoading(true);
            MemberAPI.register(inputData).then(() => {
                setIsLoading(false);
                AlertUtil.done("Thank you for register").then(() => {
                    return navigate(`/member/login`);
                })
            }).catch((apiError) => {
                setIsLoading(false);
                setErrors(apiError);
            });
        });
    }

    const buttons = [
        <button type="submit" className="th-btn fw-btn" >
            Register Now <i className="fa-regular fa-arrow-right"></i>
        </button>
    ]

    return (
        <BoxContainer isLoading={isLoading}>
            <DataForm
                onSubmit={onSubmit}
                fields={fields}
                refForm={reactHookForm}
                buttons={buttons}
                errors={errors}
            >
            </DataForm>
        </BoxContainer>
    );
};