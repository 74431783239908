import React from "react";
import { SUBSERVICE_TYPE } from "./constants";

const data = [
    { key: SUBSERVICE_TYPE.LIMOUSINE_TRANSFER, label: "Airport Transfer" },
    { key: SUBSERVICE_TYPE.LIMOUSINE_POINT_TO_POINT, label: "Airport Point to Point" },
    { key: SUBSERVICE_TYPE.DISPOSAL_WITHIN, label: "Disposal Within" },
    { key: SUBSERVICE_TYPE.DISPOSAL_OUTSIDE, label: "Disposal Outside" },
];

export default data;

export const SubserviceTypeComponent = ({ value }: { value: string }) => {
    const label = data.find((f) => f.key === value)?.label ?? "";
    let badgeType = "";
    switch (value) {
        case SUBSERVICE_TYPE.DISPOSAL_WITHIN:
            badgeType = "warning";
            break;
        case SUBSERVICE_TYPE.DISPOSAL_OUTSIDE:
            badgeType = "primary";
            break;
        case SUBSERVICE_TYPE.LIMOUSINE_TRANSFER:
            badgeType = "danger";
            break;
        case SUBSERVICE_TYPE.LIMOUSINE_POINT_TO_POINT:
            badgeType = "info";
            break;
    }

    return <span className={`fw-bolder text-uppercase text-${badgeType}`}>{label}</span>;
};
