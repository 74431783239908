import React, { useEffect, useMemo, useRef } from "react"
import { InputFieldPropType } from "types/inputField.type";
import { DateUtil } from "@standard/utils";
const $ = window.$;

export default (props: InputFieldPropType) => {
    const input = useRef(null);

    const onValueChanged = (value: any) => {
        if (props.onChange && props.onChange instanceof Function) {
            props.onChange({ value: value, name: props.name })
        }
    }

    useEffect(() => {
        $(input.current).datetimepicker({ timepicker: !1, datepicker: !0, format: "y/m/d", step: 10, onChangeDateTime: onValueChanged });
    }, []);

    const defaultValue = useMemo(() => {
        if (props.value) {
            if (props.value instanceof Date) return DateUtil.convertDateToString(props.value);
            // else if (typeof(props.value) instanceof String) DateUtil.convertDateToString(new Date(props.value.toString()))
        }

        return props.value
    }, [props.value])

    return (<div className={`form-group col-sm-${props.col ?? 6}`}>
        <input
            ref={input}
            type="text"
            className="date-pick form-control"
            name={props.name}
            id={props.name}
            placeholder={props.placeholder || "Select Date"}
            onChange={onValueChanged}
            defaultValue={defaultValue ?? ""}
            disabled={props.disabled === undefined ? false : props.disabled}
        />
        <i className="fal fa-calendar-days"></i>
    </div>)
}