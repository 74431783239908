import { NumberUtil } from '@standard/utils';
import { getSettingData } from 'features/setting/settingSelectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { memo } from 'react';
const $ = window.$;

const SectionCounter: React.FC = () => {
    const setting = useSelector(getSettingData);

    useEffect(() => {
        if (setting.loadingStatus === "successed") {
            $('.counter-number').counterUp({
                delay: 10,
                time: 1000
            });
        }
    }, [setting.loadingStatus])

    return (<section className="bg-title space" data-bg-src="assets/img/bg/counter_bg_1.jpg" data-overlay="overlay1" data-opacity="8">
        <div className="container">
            <div className="counter-wrap">
                <div className="counter-line"></div>
                <div className="row gy-40">
                    <div className="col-sm-6 col-lg-4">
                        <div className="counter-card wow fadeInUp">
                            <div className="counter-card_icon">
                                <img src="/theme/assets/img/icon/counter_1_1.svg" alt="icon" />
                            </div>
                            <h3 className="counter-card_number">
                                <span className="counter-number">{NumberUtil.convertToMoney(setting.counter.viewing)}</span>
                            </h3>
                            <p className="counter-card_text">Special Vehicles</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <div className="counter-card wow fadeInUp">
                            <div className="counter-card_icon">
                                <img src="/theme/assets/img/icon/counter_1_2.svg" alt="icon" />
                            </div>
                            <h3 className="counter-card_number">
                                <span className="counter-number">{NumberUtil.convertToMoney(setting.counter.booking)}</span>
                            </h3>
                            <p className="counter-card_text">Road Trips Done</p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <div className="counter-card wow fadeInUp">
                            <div className="counter-card_icon">
                                <img src="/theme/assets/img/icon/counter_1_4.svg" alt="icon" />
                            </div>
                            <h3 className="counter-card_number">
                                <span className="counter-number">{NumberUtil.convertToMoney(setting.counter.satisfies)}</span>
                            </h3>
                            <p className="counter-card_text">Satisfied Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default memo(SectionCounter)