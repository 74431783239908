import React, { memo } from 'react'

const ScrollToTop: React.FC = () => {
    return (
        <div className="position-relative space" id="contact-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mb-40 mb-lg-0 wow fadeInLeft">
                        <div className="title-area mb-35 text-lg-start">
                            <span className="sub-title">CAR BOOKING FORM</span>
                            <h2>
                                Happy to serve you<br />every level of service is definitely impressive.
                            </h2>
                        </div>
                        <div className="about-quote mb-40">
                            <p className="text">Our quality team is available 24 hours a day, 365 days a day, no worries if you need help.</p>

                        </div>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <div className="info-card">
                                <div className="info-card_icon">
                                    <i className="fas fa-phone"></i>
                                </div>
                                <div className="info-card_content">
                                    <p className="info-card_text">Call for book an order:</p>
                                    <a href="tel:+66929096446" className="info-card_link" target="new">+6692 909 6446</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="body-shape1">
                <img src="/theme/assets/img/normal/body_shape_1.png" alt="car" />
            </div>
        </div>
    )
}


export default memo(ScrollToTop);