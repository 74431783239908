import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil, AuthUtil } from "@standard/utils";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import forgetPasswordSchema from "./forgetPassword.schema";
import { useForm } from "react-hook-form";

const defaultValues = {
    email: "",
};

const ForgetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const reactHookForm = useForm({ defaultValues, resolver: joiResolver(forgetPasswordSchema) });
    const [errors, setErrors] = useState("");

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Forgot Password");
    }, [setTitle])

    const fields = [
        { name: "email", label: "Email", type: "email", icon: <i className="fal fa-envelope" /> },
    ];

    const onSubmit = (inputData: any) => {
        AlertUtil.confirm("Do you want to reset password ?").then((res) => {
            if (res === true) {
                setIsLoading(true);
                setErrors("");

                AuthUtil.forgetPassword(inputData.email).then(() => {
                    setIsLoading(false);
                    AlertUtil.done("Your reset password link is sent to your email.").then(() => {
                        // redirect to login
                        navigate("/member/login");
                    })
                }).catch((apiError) => {
                    setIsLoading(false);
                    setErrors(apiError);
                });;
            }
        })
    }

    return (
        <BoxContainer isLoading={isLoading}>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                    <DataForm
                        onSubmit={onSubmit}
                        fields={fields}
                        refForm={reactHookForm}
                        errors={errors}
                    >
                        <div className="row mb-3">
                            <div className="col-12">
                                <button type="submit" className="th-btn fw-btn" >
                                    Reset Password <i className="fa-regular fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                        <p className="mb-0">
                            <Link className="btn-inline" to={`/member/login`}>Back to login</Link>
                        </p>
                    </DataForm>
                </div>
            </div>
        </BoxContainer>
    );
};

export default ForgetPassword;