import Joi from "joi";

export default Joi.object({
    telephone: Joi.string().required().messages({
        'any.required': `{{#label}} is not allowed to be empty`
    }).label('Phone Number'),
    name: Joi.string().required().label('Your Name'),
    weChatId: Joi.string().optional().allow(''),
    whatAppId: Joi.string().optional().allow(''),
    lineId: Joi.string().optional().allow(''),
    telephoneCountry: Joi.string().optional().allow(''),
}).custom((value, helpers) => {
    if (!value.weChatId && !value.whatAppId && !value.lineId) {
        return helpers.message({ "custom": "Please input at least one WeChat Id / WhatApp Id / Line Id" });
    }

    return value;
}).options({ abortEarly: false, allowUnknown: true })
