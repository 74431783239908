import { ThemeContext } from 'components/Root';
import { ServiceData } from 'data';
import React, { useContext, useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom';
import { IConfigServices } from 'types/configService.type';


const configServices = ServiceData as IConfigServices[];

type ServiceParams = {
    serviceType: string;
}

export default () => {
    const { serviceType } = useParams<ServiceParams>();

    const service: IConfigServices = useMemo(() => {
        const configService = configServices.find(f => f.key === serviceType);
        if (configService) return configService;

        return {
            title: "",
            subservices: []
        }
    }, [serviceType]);

    const { setTitle } = useContext(ThemeContext);

    useEffect(() => {
        setTitle(service.title);
    }, [JSON.stringify(service)]);

    return (
        <section className="overflow-hidden space" id="service-sec">
            <div className="container">
                <div className="row gy-4">
                    {service.subservices.map((subservice, subserviceIndex) => {
                        const link = `/services/${serviceType}/${subservice.key}`;
                        return (
                            <div className="col-md-6 col-lg-6 col-xl-4" key={subserviceIndex}>
                                <div className="service-item style2 wow fadeInUp" data-bg-src="/theme/assets/img/service/service_shape.jpg">
                                    <div className="service-item_img">
                                        <img src={`${subservice.thumbnail}`} alt="" />
                                    </div>
                                    <div className="service-item_content">
                                        <h3 className="service-item_title">
                                            <Link to={`${link}`}>{`${subservice.title}`}</Link>
                                        </h3>
                                        <Link to={`${link}`} className="line-btn">SELECT THIS SERVICE<i className="fa-regular fa-arrow-right ms-2"></i></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
