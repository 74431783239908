import { ClientAPI } from "api";
import GoogleMap from "components/GoogleMap";
import React, { useState } from 'react';
import { Form, redirect, useParams } from "react-router-dom";

export async function action({ request, params }: any) {
  const formData = await request.formData();
  const entity = Object.fromEntries(formData);
  console.log("🚀 ~ action ~ updates:", entity)

  let bookingInfoStore: any = localStorage.getItem("bookingInfo");
  bookingInfoStore = bookingInfoStore ? JSON.parse(bookingInfoStore) : {};


  const bookingInfo = {
    ...bookingInfoStore,
    ...formData,
    distance: JSON.parse(entity.distance),
    startDestination: entity.startDestination,
    endDestination: entity.endDestination,
  };

  localStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));

  return redirect(`/vehicle`);
}


export default () => {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    startDestinationLocation: "",
    endDestinationLocation: "",
    distance: {
      value: '',
      text: '',
    },
  });

  const onChange = (mapValue: any) => {
    setFormData(mapValue);
  }

  // const onSubmitForm = (e: Event) => {
  //   e.preventDefault();
  //   const form = e.target;

  //   let bookingInfoStore = localStorage.getItem("bookingInfo");
  //   bookingInfoStore = bookingInfoStore ? JSON.parse(bookingInfoStore) : {};


  //   const bookingInfo = {
  //     // ...bookingInfoStore,
  //     // ...formData,
  //     // distance: formData.distance.value,
  //     // startDestination: form.startDestination.value,
  //     // endDestination: form.endDestination.value,
  //   };

  //   localStorage.setItem("bookingInfo", JSON.stringify(bookingInfo));

  //   return redirect(`/car`);
  // };

  if (isLoading) return <h3>Loading...</h3>;

  return (
    <div className="container">
      <Form
        method="POST"
        className="booking-form-area ajax-booking"
      >
        <div className="booking-title-area">
          <h4 className="booking-title">Make Your Booking Today</h4>
          <p className="booking-desc">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using is that it has distribution of letters to using content
            here making it look like readable.
          </p>
        </div>
        <div className="row">
          {/* <GoogleMap onChange={onChange} /> */}
          <div className="form-btn col-12">
            <button type="submit" className="th-btn fw-btn">
              Select Car <i className="fa-regular fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <p className="form-messages mb-0 mt-3"></p>
        <input type="hidden" name="distance" value={JSON.stringify(formData.distance)} />
      </Form>
    </div>
  );
};
