import axios from "@standard/axios";
import { IBookingItem } from "types/booking.type";
import { IOrder } from "types/order.type";
import { ITotal } from "types/total.type";
import { IVehicleType } from "types/vehicle.type";

type OrderFNType = (bookingInfo: IBookingItem) => Promise<IOrder>;
const order: OrderFNType = (bookingInfo: IBookingItem) => {
  const promise = new Promise<IOrder>((resolve, reject) => {
    axios
      .post("client/order", bookingInfo)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.status !== 401 && err.status !== 403) reject(err.message)
      });
  });

  return promise;
};

const getTotal = (bookingInfo: IBookingItem, couponCode: string = ""): Promise<ITotal> => {
  const promise = new Promise<ITotal>((resolve, reject) => {
    const url = `client/order/total/${bookingInfo.serviceType}/${bookingInfo.subserviceType}/${bookingInfo.vehicleId}`;
    axios
      .get(`${url}?distance=${bookingInfo.distance}&hours=${bookingInfo.hours}&couponCode=${couponCode}`)
      .then((res) => resolve(res.data as ITotal))
      .catch((err) => reject(err.message));
  });

  return promise;
};

const findVehicles = (props: Partial<IBookingItem>): Promise<IVehicleType[]> => {
  const promise = new Promise<IVehicleType[]>((resolve, reject) => {
    axios
      .get("client/vehicle", {
        params: {
          serviceType: props.serviceType,
          subserviceType: props.subserviceType,
          hours: props.hours,
          distance: props.distance,
          couponCode: props.couponCode,
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err.message));
  });

  return promise;
};

const findOneVehicle = (id: string) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`client/vehicle/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

  return promise;
};

const getInitialData = () => {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(`client/`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.message));
  });

  return promise;
};


export default {
  order,
  getTotal,
  findVehicles,
  findOneVehicle,
  getInitialData,
}