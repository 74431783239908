import { AlertUtil } from '@standard/utils';
import React, { useRef } from 'react'
import { IOrder } from 'types/order.type';

type PaymentSubmittedFormPropType = {
    refno: number;
    net: number;
    email: string;
    productDetail: string;
}

const createPaymentSubmittedForm = ({
    refno,
    net,
    email,
    productDetail,
}: PaymentSubmittedFormPropType) => {
    const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
    const MERCHANT_ID = process.env.REACT_APP_PAYMENT_MERCHANT_ID;

    const createChildElement = (name: string, value: any) => {
        const input = document.createElement("input");
        input.type = 'hidden';
        input.name = name;
        input.value = value;

        return input;
    }
    const form = document.createElement('form');
    form.method = "post";
    form.action = PAYMENT_URL!;
    form.appendChild(createChildElement("customeremail", email));
    form.appendChild(createChildElement("productdetail", productDetail));
    form.appendChild(createChildElement("refno", refno));
    form.appendChild(createChildElement("merchantid", MERCHANT_ID));
    form.appendChild(createChildElement("cc", "00"));
    form.appendChild(createChildElement("total", net));
    form.appendChild(createChildElement("lang", "EN"));

    document.body.appendChild(form);

    form.submit();
}

type PaymentFormType = {
    onOrderConfirmed: () => Promise<IOrder>;
}

export default function PaymentForm({ onOrderConfirmed }: PaymentFormType) {
    const onClickOrder = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        AlertUtil.confirm("Are you sure to place order ?").then(async (res) => {
            if (res === true) {
                const order: IOrder = await onOrderConfirmed();

                createPaymentSubmittedForm({
                    refno: order.refno,
                    net: order.net,
                    email: order.email,
                    productDetail: order.documentNo,
                });
            }
        })
    };

    return (
        <button onClick={onClickOrder} className="th-btn fw-btn" name="placeOrderNow">
            Place order now <i className="fa-light fa-money-bill-1-wave"></i>
        </button>
    )
}
