import React from 'react'
import { Link } from 'react-router-dom'

export default function SideMenu() {
    return (
        <div className="sidemenu-wrapper d-none d-lg-block">
            <div className="sidemenu-content bg-title">
                <button className="closeButton sideMenuCls">
                    <i className="far fa-times"></i>
                </button>
                <div className="widget footer-widget">
                    <h3 className="widget_title">About Company</h3>
                    <div className="th-widget-about">
                        <p className="footer-text">
                            We provide 24/7 Transportation and VIP Airport Ground Service,
                            we have Professional & Courteous Drivers, getting you to your
                            destination on time.
                        </p>
                        <h4 className="footer-info-title">WE ARE AVAILABLE</h4>
                        <p className="footer-text">provide 24/7</p>
                        <Link to={"/contact"} className="th-btn style3"><span className="btn-text">Contact Us</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
