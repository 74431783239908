import { BehaviorSubject } from "rxjs";
import Alert from "./alert.util";
import { SOCIAL_MEDIA_PROVIDER, STORAGE_NAME } from "@standard/constants";
import { AuthAPI } from "../api";
import cookie from "./cookie.util";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER))
);

function isAuth() {
  const currentUser = getCurrentUser();

  if (currentUser === null) return false;

  return true;
}

function changePasswordFromResetPassword({
  token,
  newPassword,
  confirmPassword,
}) {
  var promise = AuthAPI.changePasswordFromResetPassword({
    token,
    newPassword,
    confirmPassword,
  });
  return promise;
}

function forgetPassword(email) {
  var promise = AuthAPI.forgetPassword(email);
  return promise;
}

function login({ username, password, remember }) {
  var promise = new Promise(function (resolve, reject) {
    AuthAPI.login(username, password, remember)
      .then((res) => {
        const currentUser = res.user;
        const token = res.token;
        setCurrentUser(currentUser, token);
        resolve(currentUser);
      })
      .catch((error) => reject(error));
  });
  return promise;
}

const getSocialParams = (provider, data) => {
  let returnData = {
    provider,
    email: data.email,
    name: data.name,
  };

  switch (provider) {
    case SOCIAL_MEDIA_PROVIDER.GOOGLE:
      returnData = {
        ...returnData,
        accessToken: data.access_token,
        expiresIn: data.expires_in,
        id: data.sub,
      }
      break;
    case SOCIAL_MEDIA_PROVIDER.FACEBOOK:
      returnData = {
        ...returnData,
        accessToken: data.accessToken,
        expiresIn: data.expiresIn,
        id: data.id,
      }
      break;
  }

  return returnData;
}

function loginSocial({ provider, data }) {
  var promise = new Promise(function (resolve, reject) {
    const params = getSocialParams(provider, data);

    AuthAPI.loginSocial(params)
      .then((res) => {
        const currentUser = res.user;
        const token = res.token;
        setCurrentUser(currentUser, token);
        resolve(currentUser);
      })
      .catch((error) => reject(error));
  });
  return promise;
}

function logout() {
  Alert.confirm("Do you want to Logout ?", "Logout").then((res) => {
    if (res === true) doLogout();
  });
}

function doLogout() {
  localStorage.removeItem(STORAGE_NAME.CURRENT_USER);
  localStorage.removeItem(STORAGE_NAME.MENU_PROFILE);
  localStorage.clear();
  cookie.removeToken();
  currentUserSubject.next(null);
  window.location.href = "/";
}

function getCurrentUser() {
  return currentUserSubject.value;
}

function getMenuProfiles() {
  const menuProfile = localStorage.getItem(STORAGE_NAME.MENU_PROFILE);

  return menuProfile;

  // if (menuProfile === null) doLogout();
}

function setCurrentUser(currentUser, token = null) {
  localStorage.setItem(STORAGE_NAME.CURRENT_USER, JSON.stringify(currentUser));
  currentUserSubject.next(currentUser);
  if (token !== null) cookie.setToken(token);
}

const isInRoles = (roles) => {
  if (roles === undefined) return true;

  const user = getCurrentUser();
  const userRoles = user.roles;

  for (const userRole of userRoles) {
    if (roles.indexOf(userRole) !== -1) return true;
  }

  return false;
};

const auth = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  getCurrentUser,
  doLogout,
  isAuth,
  setCurrentUser,
  forgetPassword,
  changePasswordFromResetPassword,
  isInRoles,
  getMenuProfiles,
  loginSocial,
};

export default auth;
