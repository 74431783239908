export default [
    {
        key: "limousine",
        title: "LIMOUSINE SERVICE",
        description: "Luxury at an unparalleled level and the utmost attention to safety are things that passengers will receive equally.” Because every customer is most important person",
        subservices: [
            {
                key: "transfer",
                title: "Airport Transfer",
                thumbnail: '/theme/assets/img/update_2/service/service-6-1.jpg',
                assets: [
                    "/theme/assets/img/service/Airport-Transfer/1.jpg",
                    "/theme/assets/img/service/Airport-Transfer/2.jpg",
                    "/theme/assets/img/service/Airport-Transfer/3.jpg",
                ],
                description: `<p class="booking-desc">Airport transfer is a travel service that moves passengers from an airport to their destination, such as accommodation, local point of interest, another airport, etc. It involves various modes of transport, including shuttle buses, taxis, private cars, or limousines.</p>`,
            },
            {
                key: "pointtopoint",
                title: "Point to Point",
                thumbnail: "/theme/assets/img/update_2/service/service-6-2.jpg",
                assets: [
                    "/theme/assets/img/service/Point/1.jpg",
                    "/theme/assets/img/service/Point/2.jpg",
                    "/theme/assets/img/service/Point/3.jpg",
                ],
                description: `<p class="booking-desc">Point to point is a travel service that moves passengers from the destination to other destination,such as accommodation, local point of interest,etc. It involves various modes of transport, including shuttle buses, taxis, private cars, or limousines.</p>`
            },
        ],
    },
    {
        key: "disposal",
        title: "DISPOSAL SERVICE",
        description: "Book disposal service by hourly, more convenient and cost-effective. RV Limousine offers a variety of vehicles to choose from, from luxury sedans to limousines with driver.",
        subservices: [
            {
                key: "within",
                title: "By Hour within city",
                thumbnail: "/theme/assets/img/update_2/service/service-6-3.jpg",
                assets: [
                    "/theme/assets/img/service/By-Hour-within-city/1.jpg",
                    "/theme/assets/img/service/By-Hour-within-city/2.jpg",
                    "/theme/assets/img/service/By-Hour-within-city/3.jpg",
                ],
                description: `<p class="booking-desc">“Within City Hour Charter” service is available on a daily basis for a minimum of 6 hours of continuous use within Bangkok area with no limit on distance and drop-off points, providing greater flexibility and value for money in arranging services. This service is only available for tours within Bangkok area. Additional charges will apply for tours outside the area. This service includes fuel costs.</p>`,
            },
            {
                key: "outside",
                title: "By Hour outside city",
                thumbnail: "/theme/assets/img/update_2/service/service-6-4.jpg",
                assets: [
                    "/theme/assets/img/service/By-Hour-outside-city/1.jpg",
                    "/theme/assets/img/service/By-Hour-outside-city/2.jpg",
                    "/theme/assets/img/service/By-Hour-outside-city/3.jpg",
                ],
                description: `<p class="booking-desc">“Hourly charter outside city with driver” service is provided on a daily basis for a minimum of 10 hours of continuous use in provincial areas (Pattaya, Ayutthaya, Samut Prakan, Samut Sakhon or nearby areas) with no distance limit, increasing flexibility and value for money in service management. This service includes fuel costs.</p>`,
            },
        ]
    },
    {
        key: "airport",
        title: "AIRPORT SERVICE",
        description: "We assist in navigating the airport, checking in and if you need speed, we'll take you on a buggy. Even more important as it can provide information and assistance with customs and immigration processes.",
        subservices: [
            {
                key: "golfcart",
                title: "Golf Cart",
                thumbnail: "/theme/assets/img/update_2/service/service-6-5.jpg",
                assets: [

                    "/theme/assets/img/service/Golf/1.jpg",
                    "/theme/assets/img/service/Golf/2.jpg",
                    "/theme/assets/img/service/Golf/3.jpg",
                ],
                description: '<p class="booking-desc">Golf cart service to pick up from the air plane gate (Air Bridge) for arrivals at Suvarnabhumi Airport only Transfer between the arrival gate and the immigration area for international arrivals. However, the golf cart service can only be used at Gate C, E, F, and G. If the aircraft lands at the airport building at the shuttle gate (BUS GATE) or Gate D in front of the Immigration Buggy point, the golf cart service will be automatically canceled.</p>'
            },
            {
                key: "meetassist",
                title: "Meet & Assist",
                thumbnail: "/theme/assets/img/update_2/service/service-6-6.jpg",
                assets: [
                    "/theme/assets/img/service/Meet/1.jpg",
                    "/theme/assets/img/service/Meet/2.jpg",
                    "/theme/assets/img/service/Meet/3.jpg",
                ],
                description: `<p class="booking-desc">The greeter will meet & greet with you at the arrival gate, holding a sign with your name.She or he will guide you to the exclusive VIP fast track area, after checking your travel documents, to ensure that your official entry into Thailand occurs without any delays.</p><p class="booking-desc">After collecting your luggege from  baggage belt area, she or he will then guide you through customs and deliver you to one of our limousines,  If you are traveling by other means of transportation, you can talk to the greeter directly</p>`
            },
            {
                key: "porter",
                title: "Porter",
                thumbnail: "/theme/assets/img/update_2/service/service-6-7.jpg",
                assets: [
                    "/theme/assets/img/service/Porter/1.jpg",
                    "/theme/assets/img/service/Porter/2.jpg",
                    "/theme/assets/img/service/Porter/3.jpg",
                ], description: `<p class="booking-desc">If your luggage is too much, you can request a porter to help you lighten it up. Our staff will assist you from the baggage claim belt to your car or your request.</p>`
            },
            {
                key: "viplounge",
                title: "Vip Lounge",
                thumbnail: "/theme/assets/img/update_2/service/service-6-8.jpg",
                assets: [
                    "/theme/assets/img/service/VIP-Lounge/1.jpg",
                    "/theme/assets/img/service/VIP-Lounge/2.jpg",
                    "/theme/assets/img/service/VIP-Lounge/3.jpg",
                ],
                description: `<p class="booking-desc">Start your journey in ultimate comfort and style with the very best in airport lounge services in Thailand.<ul class="menu"><li>Free Wi-Fi</li><li>Snacks and drinks</li><li>Lounge</li><li>bathroom</li></ul></p>`
            },
            {
                key: "visaonarrival",
                title: "Visa On Arrival",
                thumbnail: "/theme/assets/img/update_2/service/service-6-9.jpg",
                assets: [
                    "/theme/assets/img/service/Visa/1.jpg",
                    "/theme/assets/img/service/Visa/2.jpg",
                    "/theme/assets/img/service/Visa/3.jpg",
                ],
                description: `<p class="booking-desc">When you need a visa on arrival, we will prepare the documents and assist you at the immigration checkpoint.</p><p class="booking-desc"><b>Thailand On Arrival Visa Fees</b><br />There is a non-refundable entry fee of 2,000 THB that must be paid in cash at the checkpoint. It is advised that visitors acquire this amount in Thai Baht via currency exchange before arriving in Thailand.</p>`
            },
        ]
    },
];