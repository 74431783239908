import React from "react";

export const PAYMENT_STATUS = {
  PAID: "paid",
  PROCESSING: "processing",
  UNPAID: "unpaid",
};
const data = [
  { key: PAYMENT_STATUS.PAID, label: "Paid" },
  { key: PAYMENT_STATUS.PROCESSING, label: "Processing" },
  { key: PAYMENT_STATUS.UNPAID, label: "Unpaid" },
];

export default data;

export const PaymentStatusComponent = ({ value }: { value: string }) => {
  const label = data.find((f) => f.key === value)?.label ?? "";
  let badgeType = "";
  switch (value) {
    case PAYMENT_STATUS.PAID:
      badgeType = "primary";
      break;
    case PAYMENT_STATUS.PROCESSING:
      badgeType = "warning";
      break;
    case PAYMENT_STATUS.UNPAID:
      badgeType = "danger";
      break;
  }

  return <span className={`fw-bolder text-uppercase text-${badgeType}`}>{label}</span>;
};
