import React, { memo, useContext } from "react";
import { DataFormContext } from ".";

const DataFormFooter = () => {
  const { buttons } = useContext(DataFormContext) as { buttons: [] };

  return (
    <div className="form-btn col-12" >
      {buttons?.map((button: any) => button)}
    </div>
  );
};

export default memo(DataFormFooter);
