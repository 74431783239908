import axios from "@standard/axios";
import { IChangePassword } from "types/changePassword.type";
import { IUserProfile } from "types/userProfile.type";

const register = (userInfo: any) => {
    const promise = new Promise((resolve, reject) => {
        axios
            .post("client/member/auth/register", userInfo)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.message));
    });

    return promise;
};

const updateProfile = (userInfo: IUserProfile): Promise<IUserProfile> => {
    const promise = new Promise<IUserProfile>((resolve, reject) => {
        axios
            .put("client/member/auth/profile", userInfo)
            .then((res) => resolve(res.data as IUserProfile))
            .catch((err) => reject(err.message));
    });

    return promise;
};

const changePassword = (params: IChangePassword) => {
    const promise = new Promise((resolve, reject) => {
        axios
            .put("client/member/auth/changepassword", params)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.message));
    });

    return promise;
};

const resetPassword = ({ password, token }: { password: string, token: string }) => {
    const promise = new Promise((resolve, reject) => {
        axios
            .put("client/member/auth/resetpassowrd", {
                password,
                token
            })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.message));
    });

    return promise;
};

const getProfile = () => {
    const promise = new Promise((resolve, reject) => {
        axios
            .get("client/member/auth/profile")
            .then((res) => resolve(res.data as IUserProfile))
            .catch((err) => reject(err));
    });

    return promise;
};

const getOrders = (id = "") => {
    const promise = new Promise((resolve, reject) => {
        axios
            .get(`client/member/order/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });

    return promise;
};

export default {
    register,
    getProfile,
    getOrders,
    updateProfile,
    changePassword,
    resetPassword
}