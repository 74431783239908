import { ThemeContext } from "components/Root";
import { getBookingInfo } from "features/booking/bookingSelectors";
import { selectVehicle } from "features/booking/bookingSlice";
import React, { useMemo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { IBookingItem } from "types/booking.type";
import { IVehicleType } from "types/vehicle.type";
import { ClientAPI } from "api";
import "./vehicle.css";
import { NumberUtil } from "@standard/utils";
import serviceData from "data/service.data";
import { LinkToEditService } from "pages/Checkout";

interface IVehicleSingleProp {
  onBookThisCarClicked: (entity: IVehicleType) => void;
  isSelected: boolean;
  value: IVehicleType;
}

const VehicleSingle = (props: IVehicleSingleProp) => {
  return (
    <div
      className="col-xl-4 col-md-6 filter-item cat4 cat3"
      key={props.value._id}
    >
      <div className={`taxi-box ${props.isSelected ? "selected" : ""}`}>
        {props.value.price && (
          <span className="tag">
            {NumberUtil.convertToMoney(props.value.price.total)} ฿
          </span>
        )}
        <div className="taxi-box_img">
          <img src={props.value.thumbnailAssetURL} alt="taxi" />
        </div>
        <h3 className="taxi-box_title">
          <Link to={`/vehicle/${props.value._id}`}>{props.value.name}</Link>
        </h3>
        <div className="taxi-feature">
          <div className="taxi-feature_icon">
            <img src="/theme/assets/img/icon/taxi_f_1_2.svg" alt="png" />
          </div>
          <h3 className="taxi-feature_title">Passengers:</h3>
          <span className="taxi-feature_info">
            {props.value.maxPassengers} max{" "}
          </span>
        </div>
        <div className="taxi-feature">
          <div className="taxi-feature_icon">
            <img src="/theme/assets/img/icon/taxi_f_1_3.svg" alt="png" />
          </div>
          <h3 className="taxi-feature_title">Luggage:</h3>
          <span className="taxi-feature_info">
            {props.value.luggageAllowance} (Not over 28 inch){" "}
          </span>
        </div>
        <button
          className="th-btn"
          onClick={() => props.onBookThisCarClicked(props.value)}
        >
          Book This Car Now
        </button>
      </div>
    </div>
  );
};

const ServiceInformation = ({ bookingInfo }: { bookingInfo: IBookingItem }) => {
  if (!bookingInfo.startDestination) return null;

  const { serviceType, startDestination, endDestination } = bookingInfo;
  const serviceTypeTitle =
    serviceData.find((f) => f.key === serviceType)?.title ?? "";

  return (
    <div className="th-sort-bar">
      <div className="row text-start">
        <div className="col-md">
          <p className="woocommerce-result-count">
            <strong>{serviceTypeTitle}: </strong> <br />
            {startDestination && (
              <span>
                <strong><i className="fal fa-location-dot"></i> Pickup:</strong> {startDestination}
              </span>
            )}
            {endDestination && (
              <>
                <br />
                <span>
                  <strong><i className="fal fa-location-dot"></i> Drop off:</strong> {endDestination}
                </span>
              </>
            )}
            <LinkToEditService bookingInfo={bookingInfo} />
          </p>
        </div>
      </div>
    </div>
  );
};

export const loader = async (params: any, bookingInfo: IBookingItem) => {
  const vehicles = await ClientAPI.findVehicles({
    serviceType: bookingInfo.serviceType,
    subserviceType: bookingInfo.subserviceType,
    hours: bookingInfo.hours,
    distance: bookingInfo.distance,
    couponCode: bookingInfo.couponCode,
  }).catch((err) => {
    throw err;
  });

  return { vehicles };
};

export default () => {
  const { vehicles: entities } = useLoaderData() as {
    vehicles: IVehicleType[];
  };
  const { setTitle } = useContext(ThemeContext);
  const navigate = useNavigate();
  const bookingInfo: IBookingItem = useSelector(getBookingInfo);
  const dispatch = useDispatch();
  const [vehicleId, setVehicleId] = useState<string | undefined>();
  const serviceInformation = useMemo(
    () => <ServiceInformation bookingInfo={bookingInfo} />,
    [bookingInfo]
  );

  useEffect(() => {
    setTitle("Our Car");
  }, [setTitle]);

  useEffect(() => {
    if (vehicleId) {
      if (bookingInfo.serviceType) return navigate(`/booking`);

      return navigate(`/services?flag=booking`);
    }
  }, [vehicleId]);

  const onBookThisCarClicked = async (entity: IVehicleType) => {
    dispatch(selectVehicle(entity));
    setVehicleId(entity._id);
  };

  return (
    <section className="space" id="taxi-sec">
      <div className="container">
        {serviceInformation}
        <div className="row gy-30 justify-content-center">
          {entities.map((entity: IVehicleType) => (
            <VehicleSingle
              key={entity._id}
              isSelected={entity._id === bookingInfo.vehicleId}
              value={entity}
              onBookThisCarClicked={onBookThisCarClicked}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
