import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil, AuthUtil } from "@standard/utils";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import loginSchema from "./login.schema";
import { useForm } from "react-hook-form";
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login'
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons'

const defaultValues = {
    email: "",
    password: "",
};

const Login: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const reactHookForm = useForm({ defaultValues, resolver: joiResolver(loginSchema) });
    const [errors, setErrors] = useState("");

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Login");
    }, [setTitle])

    const fields = [
        { name: "email", label: "Email", type: "email", icon: <i className="fal fa-envelope" /> },
        { name: "password", label: "Password", type: "password", icon: <i className="fal fa-key" /> },
    ];

    const onSubmit = (inputData: any) => {
        setIsLoading(true);
        setErrors("");
        AuthUtil.login({ username: inputData.email, password: inputData.password, remember: false }).then(() => {
            setIsLoading(false);
            AlertUtil.done("Login Success").then(() => {
                window.location.href = "/";
            });
        }).catch((apiError) => {
            setIsLoading(false);
            setErrors(apiError);
        });
    }

    const onLoginStart = useCallback(() => {
        setIsLoading(true);
        setErrors("");
    }, [])

    const onLoginSuccess = useCallback((params: IResolveParams) => {
        AuthUtil.loginSocial({ provider: params.provider, data: params.data }).then(() => {
            setIsLoading(false);
            AlertUtil.done("Login Success").then(() => {
                window.location.href = "/";
            });
        }).catch((apiError) => {
            setIsLoading(false);
            setErrors(apiError);
        });
    }, []);

    const onLoginFail = useCallback((error: any) => {
        setIsLoading(false);
        AlertUtil.error("Cannot login with this function");
    }, []);

    const socialButtonConfig = {
        className: "w-100 m-0 mt-2"
    }

    return (
        <BoxContainer isLoading={isLoading}>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                    <DataForm
                        onSubmit={onSubmit}
                        fields={fields}
                        refForm={reactHookForm}
                        errors={errors}
                    >
                        <div className="row mb-3">
                            <div className="col-12">
                                <button type="submit" value="Log in" name="login" className="th-btn w-100">Login</button>
                            </div>
                            <LoginSocialFacebook
                                appId={process.env.REACT_APP_FB_APP_ID || ""}
                                onLoginStart={onLoginStart}
                                onResolve={(params: IResolveParams) => {
                                    onLoginSuccess(params);
                                }}
                                onReject={(err: any) => {
                                    onLoginFail(err)
                                }}
                                className="col-12"
                                scope="email public_profile"
                            >
                                <FacebookLoginButton {...socialButtonConfig} />
                            </LoginSocialFacebook>
                            <LoginSocialGoogle
                                client_id={process.env.REACT_APP_GG_APP_ID || ""}
                                onLoginStart={onLoginStart}
                                onResolve={(params: IResolveParams) => {
                                    onLoginSuccess(params);
                                }}
                                onReject={(err: any) => {
                                    onLoginFail(err)
                                }}
                                scope="email profile"
                                className="col-12"
                            >
                                <GoogleLoginButton {...socialButtonConfig} />
                            </LoginSocialGoogle>

                        </div>
                        <p className="mb-0">
                            <Link className="btn-inline" to={`/member/forgetpassword`}>Forgot Your Password?</Link> <br />
                            Don't have an account? <Link to={`/member/register`}>Sign up now</Link>
                        </p>
                    </DataForm>
                </div>
            </div>
        </BoxContainer>
    );
};

export default Login;