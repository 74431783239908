import { MemberAPI } from "api";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil, AuthUtil, NumberUtil } from "@standard/utils";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import resetPasswordSchema from "./resetPassword.schema";

const defaultValues = {
    password: "",
    confirmPassword: "",
}

export default () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState("");

    const reactHookForm = useForm({
        defaultValues, resolver: joiResolver(resetPasswordSchema)
    });

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Reset Password");
    }, [setTitle])

    const fields = [
        { name: "password", label: "Password", type: "password", col: 12, icon: <i className="fal fa-key" /> },
        { name: "confirmPassword", label: "Confirm Password", type: "password", col: 12, icon: <i className="fal fa-key" /> },
    ];

    const onSubmit = (inputData: any) => {
        AlertUtil.confirm("Do you want to reset your password ?").then(async (res: boolean) => {
            if (res === false) return;

            setIsLoading(true);
            MemberAPI.resetPassword({ password: inputData.password, token: token! }).then(() => {
                setIsLoading(false);
                AlertUtil.done("Your password was reset to new password").then(() => {
                    navigate("/member/login");
                });
            }).catch((apiError) => {
                setIsLoading(false);
                setErrors(apiError);
            });
        });
    }

    const buttons = [
        <button type="submit" className="th-btn fw-btn" >
            RESET PASSWORD <i className="fa-regular fa-arrow-right"></i>
        </button>
    ]

    return (
        <BoxContainer isLoading={isLoading}>
            <DataForm
                onSubmit={onSubmit}
                fields={fields}
                refForm={reactHookForm}
                buttons={buttons}
                errors={errors}
            >
            </DataForm>
        </BoxContainer>
    );
};
