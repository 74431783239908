import React from 'react'
import InputPhoneNumber from './InputPhoneNumber'
import InputText from './InputText'
import InputEmail from './InputEmail'
import InputPassword from './InputPassword'
import InputTextArea from './InputTextArea'
import InputDate from './InputDate'
import InputTime from './InputTime'
import InputNumber from './InputNumber'

export default function ({ field, onChange, value }: any) {

    switch (field.type) {
        case "text": return <InputText name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "number": return <InputNumber name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "telephone": return <InputPhoneNumber name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "email": return <InputEmail name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "password": return <InputPassword name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "textarea": return <InputTextArea name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "date": return <InputDate name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        case "time": return <InputTime name={field.name} placeholder={field.label} icon={field.icon} onChange={onChange} col={field.col ?? 12} disabled={field.disabled} value={value} />
        default: return field.label
    }
}
