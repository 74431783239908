import React from "react"
import { InputFieldPropType } from "types/inputField.type";
const $ = window.$;

export default (props: InputFieldPropType) => {
    const onValueChanged = (e: any) => {
        if (props.onChange && props.onChange instanceof Function) props.onChange({ value: e.target.value, name: props.name })
    }

    return (<div className={`form-group col-sm-${props.col ?? 6}`}>
        <input
            type="number"
            className="form-control"
            name={props.name}
            id={props.name}
            placeholder={props.placeholder}
            onChange={onValueChanged}
            defaultValue={props.value ?? ""}
            min={0}
            disabled={props.disabled === undefined ? false : props.disabled}
        />
        {props.icon ?? ""}
    </div>)
}