import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getBookingInfo } from 'features/booking/bookingSelectors';
import { useSelector } from 'react-redux';
import Vehicles, { loader as vehiclesLoader } from "pages/Vehicles";
import Place, { action as placeAction } from "pages/Place";
import Booking, { loader as bookingLoader } from "pages/Booking";
import Checkout, { loader as checkoutLoader } from "pages/Checkout";
import ErrorPage from "pages/ErrorPage";
import Root from 'components/Root';
import Services from 'pages/Services';
import HowToBooking from 'pages/HowToBooking';
import Contact from 'pages/Contact';
import Subservice from 'pages/Services/Subservice';
import Subservices from 'pages/Services/Subservices';
import Home from "pages/Home";
import Vehicle, { loader as vehicleLoader } from "pages/Vehicles/Vehicle";
import Register from "pages/Member/Register";
import Profile, { loader as profileLoader } from "pages/Member/Profile";
import Orders, { loader as orderLoader } from "pages/Member/Orders";
import Login from "pages/Member/Login";
import ChangePassword from "pages/Member/ChangePassword";
import ResetPassword from "pages/Member/ResetPassword";
import ForgetPassword from "pages/Member/ForgetPassword";
import Preloader from "components/Root/Preloader";
import RootAuth from "components/Root/Auth";
import ErrorAccessDenied from "pages/ErrorPage/401";
import Container from "components/Container";

function App() {
  const bookingInfo = useSelector(getBookingInfo);

  const router = createBrowserRouter([
    {
      element: <Container />,
      children: [
        {
          path: "/",
          element: <Home />,
          errorElement: <Root><ErrorPage /></Root>,
        },
        {

          element: <Root />,
          children: [
            {
              // index: true, // set default
              errorElement: <ErrorPage />,
              children: [
                {
                  path: "error/401",
                  element: <ErrorAccessDenied />
                },
                {
                  path: "vehicles",
                  element: <Vehicles />,
                  loader: params => vehiclesLoader(params, bookingInfo),
                },
                {
                  path: "vehicle/:vehicleId?",
                  element: <Vehicle />,
                  loader: vehicleLoader,
                },
                {
                  path: "booking",
                  element: <Booking />,
                  loader: params => bookingLoader(params, bookingInfo),
                },
                {
                  path: "checkout",
                  element: <Checkout />,
                  loader: params => checkoutLoader(params, bookingInfo),
                },
                {
                  path: "place",
                  element: <Place />,
                  action: placeAction
                },
                {
                  path: "howtobook",
                  element: <HowToBooking />,
                },
                {
                  path: "contact",
                  element: <Contact />,
                },
                {
                  path: "services",
                  element: <Services />,
                },
                {
                  path: "services/:serviceType",
                  element: <Subservices />,
                }, {
                  path: "services/:serviceType/:subserviceType",
                  element: <Subservice />,
                },
                {
                  path: "member/register",
                  element: <Register />,
                },
                {
                  path: "thankyou",
                  // async loader({ request, params }) {
                  //   let { loader } = await import("./projects-loader");
                  //   return loader({ request, params });
                  // },
                  async lazy() { let component = await import("pages/Thankyou"); return { Component: component.default } },
                },
                {
                  path: "member/login",
                  element: <Login />
                },
                {
                  path: "member/forgetpassword",
                  element: <ForgetPassword />,
                },
                {
                  path: "member/resetpassword/:token",
                  element: <ResetPassword />,
                },
                {
                  element: <RootAuth />,
                  children: [
                    {
                      path: "member/profile",
                      element: <Profile />,
                      loader: profileLoader,
                    },
                    {
                      path: "member/changepassword",
                      element: <ChangePassword />,
                      loader: profileLoader,
                    },

                    {
                      path: "member/orders",
                      element: <Orders />,
                      loader: orderLoader,
                    },
                  ]
                },
              ]
            },
          ],
        },
      ]
    },
  ]);


  return <><Preloader /><RouterProvider router={router} /></>;
}

export default App;
