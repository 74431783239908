import { HelperUtil } from '@standard/utils';
import { fetchInitialSettingData } from 'features/setting/settingSlice';
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigation } from 'react-router-dom'
import { AppDispatch } from 'store';
const $ = window.$;

const scripts = [
    { name: "main", url: "/theme/assets/js/main.js" },
];

function isScriptExists(url: string) {
    const script = document.querySelector(`script[src='${url}']`);

    if (script) return true

    return false;
}

function loadScript({ name, url }: { name: string, url: string }) {
    return new Promise((resolve, reject) => {
        if (isScriptExists(url) === true) return resolve({});

        const script = document.createElement("script")
        script.type = "text/javascript";
        script.onload = function () {
            resolve({});
        };

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    })
}

function initiScriptOneTime() {
    function popupSideMenu($sideMenu: any, $sideMunuOpen: any, $sideMenuCls: any, $toggleCls: any) {
        // Sidebar Popup
        $($sideMunuOpen).on('click', function (e: any) {
            e.preventDefault();
            $($sideMenu).addClass($toggleCls);
        });
        $($sideMenu).on('click', function (e: any) {
            e.stopPropagation();
            $($sideMenu).removeClass($toggleCls)
        });
        var sideMenuChild = $sideMenu + ' > div';
        $(sideMenuChild).on('click', function (e: any) {
            e.stopPropagation();
            $($sideMenu).addClass($toggleCls)
        });
        $($sideMenuCls).on('click', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            $($sideMenu).removeClass($toggleCls);
        });
    };
    popupSideMenu('.sidemenu-wrapper', '.sideMenuToggler', '.sideMenuCls', 'show');
}

function initScriptPageChanged() {
    /* magnificPopup img view */
    if ($('.popup-image').length > 0) {
        $('.popup-image').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }

    if ($('[data-bg-src]').length > 0) {
        $('[data-bg-src]').each(function () {
            var src = $(this).attr('data-bg-src');
            $(this).css('background-image', 'url(' + src + ')');
            $(this).removeAttr('data-bg-src').addClass('background-image');
        });
    };
    // Mask Image
    if ($('[data-mask-src]').length > 0) {
        $('[data-mask-src]').each(function () {
            var mask = $(this).attr('data-mask-src');
            $(this).css({
                'mask-image': 'url(' + mask + ')',
                '-webkit-mask-image': 'url(' + mask + ')'
            });
            $(this).removeAttr('data-mask-src');
        });
    };

    $(window).on("scroll", function () {
        var topPos = $(this).scrollTop();
        sticky()
        if (topPos > 150) {
            $('.sticky-wrapper').addClass('will-sticky')
            sticky()
        } else {
            $('.sticky-wrapper').removeClass('sticky')
            $('.sticky-wrapper').removeClass('will-sticky')
        }

        function sticky() {
            if (topPos > 400) {
                $('.sticky-wrapper').addClass('sticky')
                $('.sticky-wrapper').removeClass('will-sticky')
            }
        }
    })

    $('.filter-active').imagesLoaded(function () {
        var $filter = '.filter-active',
            $filterItem = '.filter-item',
            $filterMenu = '.filter-menu-active';

        if ($($filter).length > 0) {
            var $grid = $($filter).isotope({
                itemSelector: $filterItem,
                filter: '*',
                masonry: {
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: 1
                }
            });

            // filter items on button click
            $($filterMenu).on('click', 'button', function () {
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({
                    filter: filterValue
                });
            });

            // Menu Active Class 
            $($filterMenu).on('click', 'button', function (event: any) {
                event.preventDefault();
                $(this).addClass('active');
                $(this).siblings('.active').removeClass('active');
            });
        };
    });

    // Active specifix
    $('.filter-active-cat1').imagesLoaded(function () {
        var $filter = '.filter-active-cat1',
            $filterItem = '.filter-item',
            $filterMenu = '.filter-menu-active';

        if ($($filter).length > 0) {
            var $grid = $($filter).isotope({
                itemSelector: $filterItem,
                filter: '.cat1',
                masonry: {
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: 1
                }
            });

            // filter items on button click
            $($filterMenu).on('click', 'button', function () {
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({
                    filter: filterValue
                });
            });

            // Menu Active Class 
            $($filterMenu).on('click', 'button', function (event: any) {
                event.preventDefault();
                $(this).addClass('active');
                $(this).siblings('.active').removeClass('active');
            });
        };
    });

    $.fn.vsmobilemenu = function (options: any) {
        var opt = $.extend({
            menuToggleBtn: '.th-menu-toggle ',
            bodyToggleClass: 'th-body-visible',
            subMenuClass: 'th-submenu',
            subMenuParent: 'th-item-has-children',
            subMenuParentToggle: 'th-active',
            meanExpandClass: 'th-mean-expand',
            appendElement: '<span class="th-mean-expand"></span>',
            subMenuToggleClass: 'th-open',
            toggleSpeed: 400,
        }, options);

        return this.each(function () {
            var menu = $(this); // Select menu

            // Menu Show & Hide
            function menuToggle() {
                menu.toggleClass(opt.bodyToggleClass);

                // collapse submenu on menu hide or show
                var subMenu = '.' + opt.subMenuClass;
                $(subMenu).each(function () {
                    if ($(this).hasClass(opt.subMenuToggleClass)) {
                        $(this).removeClass(opt.subMenuToggleClass);
                        $(this).css('display', 'none')
                        $(this).parent().removeClass(opt.subMenuParentToggle);
                    };
                });
            };

            // Class Set Up for every submenu
            menu.find('li').each(function () {
                var submenu = $(this).find('ul');
                submenu.addClass(opt.subMenuClass);
                submenu.css('display', 'none');
                submenu.parent().addClass(opt.subMenuParent);
                submenu.prev('a').append(opt.appendElement);
                submenu.next('a').append(opt.appendElement);
            });

            // Toggle Submenu
            function toggleDropDown($element: any) {
                if ($($element).next('ul').length > 0) {
                    $($element).parent().toggleClass(opt.subMenuParentToggle);
                    $($element).next('ul').slideToggle(opt.toggleSpeed);
                    $($element).next('ul').toggleClass(opt.subMenuToggleClass);
                } else if ($($element).prev('ul').length > 0) {
                    $($element).parent().toggleClass(opt.subMenuParentToggle);
                    $($element).prev('ul').slideToggle(opt.toggleSpeed);
                    $($element).prev('ul').toggleClass(opt.subMenuToggleClass);
                };
            };

            // Submenu toggle Button
            var expandToggler = '.' + opt.meanExpandClass;
            $(expandToggler).each(function () {
                $(this).on('click', function (e: any) {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleDropDown($(this).parent());
                });
            });

            // Menu Show & Hide On Toggle Btn click
            $(opt.menuToggleBtn).each(function () {
                $(this).on('click', function () {
                    menuToggle();
                })
            });

            // Hide Menu On out side click
            menu.on('click', function (e: any) {
                // e.stopPropagation();
                menuToggle()
            })

            // Stop Hide full menu on menu click
            // menu.find('div').on('click', function (e: any) {
            //     e.stopPropagation();
            // });

        });
    };

    $('.th-menu-wrapper:not(.active)').vsmobilemenu().toggleClass("active");
    // $(window).on('load', function () {
    //     $('.preloader').fadeOut();
    // });
}

export default () => {
    const location = useLocation();

    useEffect(() => {
        initScriptPageChanged();
        initiScriptOneTime();

        HelperUtil.goToTop();
    }, [location]);

    useEffect(() => {
        scripts.map(script => loadScript(script));
    }, []);

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInitialSettingData()).finally(() => hideLoading());
    }, [dispatch])

    const hideLoading = () => {
        $('.preloader').fadeOut();
    }

    // useEffect(() => {
    //   if (isLoading === false) {
    //     $('.preloader').fadeOut();
    //   }
    // }, [isLoading])

    return (
        <Outlet />
    )
}
