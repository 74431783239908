import Loader from 'components/Loader';
import React, { ReactNode } from 'react'

interface IProps {
    children?: ReactNode;
    isLoading?: boolean;
}
export default function ({ children, isLoading = false }: IProps) {
    return (
        <Loader isLoading={isLoading}>
            <div className="space">
                <div className="container">
                    {children}
                </div>
            </div>
        </Loader>
    )
}
