import axios from "axios";
import { STORAGE_NAME } from "@standard/constants";
import { AlertUtil, AuthUtil, CookieUtil } from "./utils";

const config = {
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: true,
  params: {},
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  validateStatus: function (status: number) {
    return status >= 200 && status < 300; // default
  },
};

const instance = axios.create(config);

// const item = localStorage.getItem(STORAGE_NAME.CURRENT_USER);
// const user = item ? JSON.parse(item) : null;

// if (user) {
//   const token = CookieUtil.getToken();
//   instance.defaults.headers.common["Authorization"] = "Bearer " + token;
// }

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("axios before loading", config);
    return config;
  },
  function (error) {
    // console.log("axios error before loading", config);
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // store.dispath(setLoading(false));
    // console.log("axios after loading", response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      return Promise.reject({
        status: 500,
        success: false,
        message: ["Unexpected error, please contact admin"],
      });
    }

    const { status, data } = error.response;
    if (status === 401 || status === 403) {
      AlertUtil.error("Your session was expired").then((res) => {
        if (res.isConfirmed !== undefined)
          AuthUtil.doLogout();
      })
    } else if (status === 404) {
      // redirect to 404
      console.log("Response page not found");
    } else if (status === 500) {
    } else if (status === 400) {
    }

    return Promise.reject(data);
  }
);

export default instance;
