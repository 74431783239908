import { ThemeContext } from "components/Root";
import { useContext, useEffect } from "react";
import React from "react";

export default () => {
    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Error 401 !!!");
    }, [setTitle])


    return (
        <section className="space">
            <div className="container">
                <div className="error-content">
                    <h2 className="error-title"><span className="text-theme">Sorry,</span> an unexpected error has occurred.</h2>
                    <p className="error-text">
                        You don't have permission to access this page
                    </p>
                    <div className="btn-group justify-content-center">
                        <a href="/" className="th-btn error-btn"><i className="fal fa-home me-2"></i>Back To Home</a>
                    </div>
                </div>
            </div>
        </section>
    )
}