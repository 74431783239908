import { getVehicles } from 'features/setting/settingSelectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { IVehicleType } from 'types/vehicle.type'
import { Link } from 'react-router-dom';
import Slide from 'components/Slide';

const Vehicle = (props: IVehicleType) => {
    return (<div className="col-md-6 col-lg-4">
        <div className="taxi-grid style2">
            <div className="taxi-grid_img">
                <img src={props.thumbnailAssetURL} alt={props.name} />
            </div>
            <div className="taxi-grid_content" >
                <h3 className="taxi-grid_title" >
                    <Link to={`/vehicle/${props._id}`}><br />{props.name}</Link>
                </h3>
                <Link to={`/vehicle/${props._id}`} className="th-btn">View Cars</Link>
            </div>
        </div>
    </div>)
}

const SectionVehicle: React.FC = () => {
    const vehicles = useSelector(getVehicles);

    return (
        <section className="space" id="taxi-sec">
            <div className="container">
                <div className="title-area text-center">
                    <span className="sub-title">
                        We Provide Updated Car<span className="double-line"></span>
                    </span>
                    <h2 className="sec-title text-capitalize">Our Vehicle Category</h2>
                </div>
                <Slide items={vehicles.map(vehicle => <Vehicle key={vehicle._id} {...vehicle} />)}></Slide>
            </div>
        </section>
    )
}

export default SectionVehicle;