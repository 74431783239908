import { NumberUtil } from "@standard/utils";
import { SERVICE_TYPE } from "data/constants";
import React, { memo } from "react";
import { IBookingItem } from "types/booking.type";
import { Link } from "react-router-dom";
import { ITotal } from "types/total.type";

function OrderList({
  bookingInfo,
  summary,
}: {
  bookingInfo: IBookingItem;
  summary: ITotal;
}) {
  const vehicle = bookingInfo.vehicle!;
  return (
    <>
      <div className="col-sm-12">
        <h4 className="mt-4 pt-lg-2">Your Order</h4>
      </div>
      <div className="col-sm-12">
        <table className="cart_table mb-20">
          <thead>
            <tr>
              <th className="cart-col-image text-center">Image</th>
              <th className="cart-col-productname text-center">Car</th>
              {bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE && (
                <>
                  <th className="cart-col-price text-center">Pickup</th>
                  <th className="cart-col-price text-center">Drop off</th>
                  <th className="cart-col-price text-center">Distance</th>
                </>
              )}
              {bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL && (
                <th className="cart-col-price text-center">Pickup</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="cart_item">
              <td data-title="Product" className="text-center">
                <img
                  width="91"
                  height="91"
                  src={vehicle.thumbnailAssetURL}
                  alt="Image"
                  className="mb-2"
                />
                <br />
                <small>
                  <Link to="/vehicles" className=" mt-2">
                    <i className="fa fa-pencil"></i> Change
                  </Link>
                </small>
              </td>
              <td data-title="Name">{vehicle.name}</td>
              {bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE && (
                <>
                  <td data-title="Pickup">{bookingInfo.startDestination}</td>
                  <td data-title="Drop off">{bookingInfo.endDestination}</td>
                  <td className="text-end" data-title="Distance">
                    {NumberUtil.convertToMoney(bookingInfo.distance! / 1000)} KM
                  </td>
                </>
              )}
              {bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL && (
                <td data-title="Pickup">{bookingInfo.startDestination}</td>
              )}
            </tr>
          </tbody>
          <tfoot className="checkout-ordertable">
            <tr
              className={`order-total ${
                summary.discount === null ? "d-none" : ""
              }`}
            >
              <th>Total</th>
              <td
                data-title="Total"
                colSpan={
                  bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE ? 5 : 3
                }
                className="text-end"
              >
                <strong>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      {NumberUtil.convertToMoney(summary.total!)}
                      <span className="woocommerce-Price-currencySymbol">
                        ฿
                      </span>
                    </bdi>
                  </span>
                </strong>
              </td>
            </tr>

            <tr
              className={`order-total ${
                summary.discount === null ? "d-none" : ""
              }`}
            >
              <th>Discount</th>
              <td
                data-title="Total"
                colSpan={
                  bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE ? 5 : 3
                }
                className="text-end"
              >
                <strong>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      - {NumberUtil.convertToMoney(summary.discount!)}
                      <span className="woocommerce-Price-currencySymbol">
                        ฿
                      </span>
                    </bdi>
                  </span>
                </strong>
              </td>
            </tr>

            <tr className="order-total">
              <th>Net</th>
              <td
                data-title="Total"
                colSpan={
                  bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE ? 5 : 3
                }
                className="text-end"
                style={{ textDecoration: "underline", fontSize: "1.2em" }}
              >
                <strong>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      {NumberUtil.convertToMoney(summary.net!)}
                      <span className="woocommerce-Price-currencySymbol">
                        ฿
                      </span>
                    </bdi>
                  </span>
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}

export default memo(OrderList);
