import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const SectionAbout: React.FC = () => {
    return (<div className="space-bottom" id="about-sec">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 mb-40 mb-xl-0">
                    <div className="img-box3 wow fadeInLeft">
                        <div className="img1">
                            <img src="/theme/assets/img/normal/about_3_1.jpg" alt="About" />
                        </div>
                        <div className="img2">
                            <img src="/theme/assets/img/normal/about_3_2.jpg" alt="About" />
                        </div>
                        <div className="img3">
                            <img src="/theme/assets/img/normal/about_3_3.jpg" alt="About" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 ps-3 ps-xl-5 wow fadeInRight">
                    <div className="title-area mb-35 text-md-start">
                        <span className="sub-title">About Us</span>
                        <h2 className="sec-title text-capitalize">
                            RV Limousine Service
                        </h2>
                    </div>
                    <p className="mt-n2 mb-30 text-md-start text-center">We provide 24/7 Transportation and VIP Airport Ground Service, we have Professional & Courteous Drivers, getting you to your destination on time.</p>
                    <p className="mt-n2 mb-30 text-md-start text-center">"RV Limousine Service is well known as one of Thailand’s premier corporations,with several subsidiaries in the Bangkok metropolitan area and multiple branches around Thailand. We strongly believe our services will meet or exceed all of your expectations and requirements."
                    </p>
                    <div className="text-center text-md-start">
                        <Link className="th-btn" to={`/services`}>Book now</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default memo(SectionAbout);