import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBookingItem, LocationType } from 'types/booking.type';
import { SubserviceType, ServiceType } from 'types/service.type';
import { IVehicleType } from 'types/vehicle.type';

const initialState: IBookingItem = {
    vehicle: {
        name: "",
        maxPassengers: 0,
        type: "",
        luggageAllowance: 0,
        thumbnailAssetURL: "",
        imageAssetsURL: [],
    },
    vehicleId: null,
    serviceType: null,
    subserviceType: null,

    name: "",
    telephone: "",
    email: "",
    flightNo: "",
    weChatId: "",
    whatAppId: "",
    lineId: "",
    numberOfluggage: null,
    numberOfPassenger: null,
    message: "",
    pickupDate: null,
    pickupTime: "",

    startDestination: "",
    endDestination: "",
    distance: 0,
    startDestinationLocation: { lat: 0, lng: 0 },
    endDestinationLocation: { lat: 0, lng: 0 },
    itinerary: "",
    hours: 0,
};

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        selectVehicle: (state, action: PayloadAction<IVehicleType>) => {
            state.vehicle = action.payload;
            state.vehicleId = action.payload._id;
        },
        selectSubService: (state, action: PayloadAction<{
            serviceType: ServiceType,
            subserviceType: SubserviceType,
            startDestination: string,
            endDestination: string,
            distance: number,
            startDestinationLocation: LocationType,
            endDestinationLocation: LocationType,
        }>) => {
            state.serviceType = action.payload.serviceType;
            state.subserviceType = action.payload.subserviceType;
            state.startDestination = action.payload.startDestination;
            state.endDestination = action.payload.endDestination;
            state.distance = action.payload.distance;
            state.startDestinationLocation = action.payload.startDestinationLocation;
            state.endDestinationLocation = action.payload.endDestinationLocation;
        },
        setBookingInfo: (state, action: PayloadAction<IBookingItem>) => {
            state.name = action.payload.name;
            state.telephone = action.payload.telephone;
            state.email = action.payload.email;
            state.flightNo = action.payload.flightNo;
            state.weChatId = action.payload.weChatId;
            state.whatAppId = action.payload.whatAppId;
            state.lineId = action.payload.lineId;
            state.numberOfluggage = action.payload.numberOfluggage;
            state.numberOfPassenger = action.payload.numberOfPassenger;
            state.message = action.payload.message;
            state.pickupDate = action.payload.pickupDate;
            state.pickupTime = action.payload.pickupTime;
            state.itinerary = action.payload.itinerary;
            state.hours = action.payload.hours;
        },
        clearBooking: (state) => {
            state.vehicle = initialState.vehicle;
            state.vehicleId = initialState.vehicleId;
            state.serviceType = initialState.serviceType;
            state.subserviceType = initialState.subserviceType;
            state.name = initialState.name;
            state.telephone = initialState.telephone;
            state.email = initialState.email;
            state.flightNo = initialState.flightNo;
            state.weChatId = initialState.weChatId;
            state.whatAppId = initialState.whatAppId;
            state.lineId = initialState.lineId;
            state.numberOfluggage = initialState.numberOfluggage;
            state.numberOfPassenger = initialState.numberOfPassenger;
            state.message = initialState.message;
            state.pickupDate = initialState.pickupDate;
            state.pickupTime = initialState.pickupTime;
            state.startDestination = initialState.startDestination;
            state.endDestination = initialState.endDestination;
            state.distance = initialState.distance;
            state.startDestinationLocation = initialState.startDestinationLocation;
            state.endDestinationLocation = initialState.endDestinationLocation;
            state.itinerary = initialState.itinerary;
            state.hours = initialState.hours;
        }
    },
});

export const { selectSubService, selectVehicle, setBookingInfo, clearBooking } = bookingSlice.actions;

export default bookingSlice.reducer;