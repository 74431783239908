import { ServiceData } from 'data';
import { getVehicles } from 'features/setting/settingSelectors';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SideMenu from './SideMenu';
import { IVehicleType } from 'types/vehicle.type';
import { AuthUtil } from '@standard/utils';
import React from "react";

interface HeaderProps {
  title: string;
}

export const MenuesComponent = () => {
  const vehicles = useSelector(getVehicles);
  const services = ServiceData;
  const isAuth = AuthUtil.isAuth();
  const userInfo = AuthUtil.getCurrentUser();

  let authMenues = <li><Link to={`/member/login`}>Login</Link></li>

  if (isAuth === true) {
    authMenues = <li className="menu-item-has-children">
      <Link to={"/member/profile"}><i className='fa fa-person'></i> {userInfo.name}</Link>
      <ul className="sub-menu">
        <li><Link to={`/member/profile`}>Profile</Link></li>
        <li><Link to={`/member/changepassword`}>Change Password</Link></li>
        <li><Link to={`/member/orders`}>Orders</Link></li>
        <li><a href='#' onClick={() => AuthUtil.logout()}>Logout</a></li>
      </ul>
    </li>
  }

  return (<ul>
    <li><Link to={"/"}>Home</Link></li>
    <li className="menu-item-has-children">
      <Link to={"/vehicles"}>Our Car</Link>
      <ul className="sub-menu">
        {vehicles.map(vehicle => (<li key={vehicle._id}>
          <Link to={`vehicle/${vehicle._id}`}>{vehicle.name}</Link>
        </li>))}
      </ul>
    </li>
    <li><Link to={`/howtobook`}>How to book</Link></li>
    <li className="menu-item-has-children">
      <Link to={`/services`}>SERVICE</Link>
      <ul className="sub-menu">
        {services && services.map(service => <li key={service.key}>
          <Link to={`/services/${service.key}`}>{service.title}</Link>
          <ul className="sub-menu">
            {service.subservices.map(subservice => <li key={subservice.key}><Link to={`services/${service.key}/${subservice.key}`}>{subservice.title}</Link></li>)}
          </ul>
        </li>)}
      </ul>
    </li>
    <li><Link to={`/contact`}>Contact</Link></li>
    {authMenues}
  </ul>
  )
}

export default ({ title }: HeaderProps) => {
  return (
    <>
      <div className="th-menu-wrapper">
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle">
            <i className="fal fa-times"></i>
          </button>
          <div className="mobile-logo">
            <Link to="/"><img src="/theme/assets/img/logo.png" alt="Taxiar" /></Link>
          </div>
          <div className="th-mobile-menu">
            {<MenuesComponent />}
          </div>
        </div>
      </div>
      <SideMenu />
      <header className="th-header header-layout7">
        <div className="header-top">
          <div className="container">
            <div className="row justify-content-center justify-content-md-between align-items-center gy-2">
              <div className="col-auto d-none d-md-block">
                <div className="header-links">
                  <ul>
                    <li className="d-none d-lg-inline-block">
                      <i className="fal fa-phone"></i>
                      <a href="tel:+66929096446" target="new">
                        +6692 909 6446
                      </a>
                    </li>
                    <li className="d-none d-xxl-inline-block">
                      <i className="fal fa-location-dot"></i>
                      118/100 Pradya BisHome Lat Krabang
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>
                      <a
                        href="mailto:reservations@rvlimousineservice.com"
                        target="new"
                      >
                        reservations@rvlimousineservice.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to={"/"}><img src="/theme/assets/img/logo.png" alt="Taxiar" /></Link>
                  </div>
                </div>
                <div className="col-auto me-xl-auto">
                  <nav className="main-menu d-none d-lg-block">
                    <MenuesComponent />
                  </nav>
                  <button className="th-menu-toggle d-inline-block d-lg-none">
                    <i className="far fa-bars"></i>
                  </button>
                </div>
                <div className="col-auto d-none d-xxl-block">
                  <div className="header-button">
                    <a href="#" className="simple-icon sideMenuToggler">
                      <i className="fa-solid fa-grid"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-shape">
          <img src="/theme/assets/img/logo-shape.svg" alt="" />
        </div>
      </header>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('/theme/assets/img/breadcumb/breadcumb-bg.jpg')" }}
        data-bg-src="/theme/assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div className="container z-index-common">
          <h1 className="breadcumb-title">{title}</h1>
          <ul className="breadcumb-menu">
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            {/* <li>
              <Link to={`/services`}>Services</Link>
            </li> */}
            <li>{title}</li>
          </ul>
        </div>
      </div>
    </>
  );
};
