import React, { memo } from 'react'

const SectionContactInfo: React.FC = () => {
    return (
        <div className="bg-black1" id="">
            <div className="container">
                <div className="contact-info-wrap">
                    <div className="info-card style3 px-3">
                        <div className="info-card_icon style2">
                            <i className="fal fa-envelope"></i>
                        </div>
                        <div className="info-card_content word-break">
                            <p className="info-card_text">Email Address</p>
                            <a href="mailto:reservations@rvlimousineservice.com" className="info-card_link">reservations@rvlimousineservice.com</a>
                        </div>
                    </div>
                    <div className="info-card style3 px-3">
                        <div className="info-card_icon style2">
                            <i className="fal fa-phone"></i>
                        </div>
                        <div className="info-card_content word-break">
                            <p className="info-card_text">Phone Number</p>
                            <a href="tel:+66929096446" className="info-card_link">+6692 909 6446</a>
                        </div>
                    </div>
                    <div className="info-card style3 px-3">
                        <div className="info-card_icon style2">
                            <i className="fal fa-location-dot"></i>
                        </div>
                        <div className="info-card_content word-break">
                            <p className="info-card_text">Our Adress</p>
                            <a href="#" className="info-card_link">118/100 Pradya BisHome Onnut-Suvarnabhumi village, Lat Krabang Rd, Lat Krabang, Bangkok 10520</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(SectionContactInfo)