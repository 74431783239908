import { ThemeContext } from 'components/Root';
import React, { useContext, useEffect } from 'react'

export default () => {
    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Contact");
    }, []);

    return (
        <>
            <section className="space" id="contact-sec">
                <div className="container">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="nav-one" role="tabpanel" aria-labelledby="nav-one-tab">
                            <div className="row gy-30 justify-content-center">
                                <div className="col-md-6 col-lg-4">
                                    <div className="contact-box">
                                        <div className="contact-box_img">
                                            <img src="/theme/assets/img/normal/contact_1_1.jpg" alt="service image" />
                                        </div>
                                        <div className="contact-box_content">
                                            <div className="contact-box_icon">
                                                <i className="fal fa-headset"></i>
                                            </div>
                                            <div className="contact-box_info">
                                                <p className="contact-box_text">Call Us 24/7</p>
                                                <h5 className="contact-box_link">
                                                    <a href="tel:+66929096446">+6692 909 6446</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="contact-box">
                                        <div className="contact-box_img">
                                            <img src="/theme/assets/img/normal/contact_1_2.jpg" alt="service image" />
                                        </div>
                                        <div className="contact-box_content word-break">
                                            <div className="contact-box_icon">
                                                <i className="fal fa-envelope-open-text"></i>
                                            </div>
                                            <div className="contact-box_info">
                                                <p className="contact-box_text">Get A Quote</p>
                                                <h5 className="contact-box_link">
                                                    <a href="mailto:reservations@rvlimousineservice.com">reservations@rvlimousineservice.com</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="contact-box">
                                        <div className="contact-box_img">
                                            <img src="/theme/assets/img/normal/contact_1_3.jpg" alt="service image" />
                                        </div>
                                        <div className="contact-box_content">
                                            <div className="contact-box_icon">
                                                <i className="fal fa-map-location-dot"></i>
                                            </div>
                                            <div className="contact-box_info">
                                                <p className="contact-box_text">Service Station</p>
                                                <h5 className="contact-box_link">118/100 Pradya BisHome Onnut-Suvarnabhumi village, Lat Krabang Rd, Lat Krabang, Bangkok 10520</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="map-sec">
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3875.985906784255!2d100.72117107508966!3d13.719302986669216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDQzJzA5LjUiTiAxMDDCsDQzJzI1LjUiRQ!5e0!3m2!1sen!2sth!4v1728113050363!5m2!1sen!2sth" width="600" height="450" style={{ "border": "0" }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}
