import { isValidElement } from "react";
import _ from "lodash";

var HtmlToReactParser = require("html-to-react").Parser;
// const HtmlToReactParser = {};
const $ = window.$;

function isReactElement(element) {
  return isValidElement(element);
}

function getProjectName() {
  const projectName = process.env.REACT_APP_PROJECT_NAME;

  return projectName;
}

function goToTop(scrollTo = 0) {
  var body = $("html, body");
  body.stop().animate({ scrollTop: scrollTo }, 500, "swing");
}

function htmlParse(html) {
  var htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(html);

  return reactElement;
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

function JSONEqual(x, y) {
  // If both x and y are null or undefined and exactly the same
  if (x === y) {
    return true;
  }

  // If they are not strictly equal, they both need to be Objects
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }

  // They must have the exact same prototype chain, the closest we can do is
  // test the constructor.
  if (x.constructor !== y.constructor) {
    return false;
  }

  for (var p in x) {
    // Inherited properties were tested using x.constructor === y.constructor
    if (x.hasOwnProperty(p)) {
      // Allows comparing x[ p ] and y[ p ] when set to undefined
      if (!y.hasOwnProperty(p)) {
        return false;
      }

      // If they have the same strict value or identity then they are equal
      if (x[p] === y[p]) {
        continue;
      }

      // Numbers, Strings, Functions, Booleans must be strictly equal
      if (typeof x[p] !== "object") {
        return false;
      }

      // Objects and Arrays must be tested recursively
      if (!JSONEqual(x[p], y[p])) {
        return false;
      }
    }
  }

  for (p in y) {
    // allows x[ p ] to be set to undefined
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}

const groupByItem = ({ entities, groupByNames, sumFields = [] }) => {
  const newEntities = [];

  entities.forEach((item) => {
    const index = newEntities.findIndex(
      (f) =>
        groupByNames.filter((groupName) => f[groupName] === item[groupName])
          .length === groupByNames.length
    );

    if (index !== -1) {
      newEntities[index].detail.push(item);
      sumFields.forEach((g) => (newEntities[index][g] += item[g] || 0));
    } else {
      const key = {};
      const keySumFields = {};
      groupByNames.forEach((g) => (key[g] = item[g]));
      sumFields.forEach((g) => (keySumFields[g] = item[g]));

      newEntities.push({
        ...key,
        ...keySumFields,
        detail: [item],
      });
    }
  });

  return newEntities;
};

function queryStringToJSON(qs) {
  qs = qs.startsWith("?") ? qs.substring(1, qs.length) : qs;
  var pairs = qs.split("&");
  var result = {};
  pairs.forEach(function (p) {
    var pair = p.split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || "");

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

const isObjEmpty = (obj) => {
  return _.isEmpty(obj);
};

export default {
  goToTop,
  htmlParse,
  arraysEqual,
  JSONEqual,
  getProjectName,
  isReactElement,
  groupByItem,
  queryStringToJSON,
  isObjEmpty,
};
