const LABEL = {
  ALERT: {
    TITLE: {
      CONFIRM: "Confirm",
      SUCCESS: "Success",
      ERROR: "Error",
      WARNING: "Warning",
    },
    TEXT: {
      CONFIRM: "Confirm",
      SUCCESS: "Success",
      ERROR: "Error",
      WARNING: "Warning",
    },
    ICON: {
      WARNING: "warning",
      SUCCESS: "success",
      ERROR: "error",
      INFO: "info",
    },
  },
};

export default LABEL;
