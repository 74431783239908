import { MemberAPI } from "api";
import React, { useContext, useEffect, useState } from "react";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { useDispatch } from "react-redux";
import { DateUtil, NumberUtil } from "@standard/utils";
import TableForm from '@standard/components/TableForm';
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import { OrderStatusComponent } from "data/orderStatus";
import { PaymentStatusComponent } from 'data/paymentStatus';
import { SubserviceTypeComponent } from "data/subserviceType";
import { SERVICE_TYPE } from "data/constants";
import "./order.css";

export const loader = async () => {
    try {
        const result = await MemberAPI.getOrders() as any;
        const orders = result.data.map((entity: any) => {
            entity.values.orderDate = DateUtil.convertDateTimeToString(entity.values.orderDate);
            entity.values.subserviceType = <SubserviceTypeComponent value={entity.values.subserviceType} />;
            entity.values.paymentStatus = <PaymentStatusComponent value={entity.values.paymentStatus} />;
            entity.values.status = <OrderStatusComponent value={entity.values.status} />;
            entity.values.place = `${entity.values.startDestination}${entity.values.endDestination ? " - " + entity.values.endDestination : ""}`;
            entity.values.netLabel = NumberUtil.convertToMoney(entity.values.net) + " ฿";

            return {
                key: entity.key,
                values: entity.values,
            }
        })

        return { orders: orders }
    } catch (ex: any) {
        if (ex.status === 401) return redirect("/error/401");

        throw ex.message;
    }
}

interface IOrder {
    key: string;
    values: {
        net: number;
    }
}

const PopupOrderDetail = (props: any) => {
    const data = props.values;
    const id = `order-detail-${data._id}`;
    return <><a className="line-btn" data-bs-toggle="modal" data-bs-target={`#${id}`}>
        {data.documentNo}
    </a><div className="modal fade" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary">
                        <h1 className="modal-title fs-5 text-white" id={`${id}Label`}>Order Detail</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="" style={{ "padding": "10px 30px" }}>
                            <h3 className="order-detail_title">{data.documentNo}</h3>
                            <p className="order-detail_subtitle"><small>{data.orderDate} • {data.paymentStatus} • {data.status}</small></p>
                            <div className="order-detail_item">
                                <span><i className="fa-light fa-location-dot"></i> Place</span>
                                <span className="">{data.place}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa-solid fa-car"></i> Vehicle</span>
                                <span className="">{data.vehicle.name}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa fa-user"></i> Your Name</span>
                                <span className="">{data.name}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-phone"></i> Phone Number</span>
                                <span className="">{data.telephone}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-envelope"></i> Email</span>
                                <span className="">{data.email}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-plane"></i> Flight Number</span>
                                <span className="">{data.flightNo}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa-brands fa-weixin"></i> We Chat</span>
                                <span className="">{data.weChatId}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa-brands fa-whatsapp"></i> What App</span>
                                <span className="">{data.whatAppId}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa-brands fa-line"></i> Line</span>
                                <span className="">{data.lineId}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-user"></i> Number of passenger</span>
                                <span className="">{data.numberOfPassenger}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-cart-flatbed-suitcase"></i> Number of luggage</span>
                                <span className="">{data.numberOfluggage}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-calendar-days"></i> Service Date</span>
                                <span className="">{DateUtil.convertDateToString(data.pickupDate)}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fal fa-clock"></i> Service Time</span>
                                <span className="">{data.pickupTime}</span>
                            </div>
                            <div className="order-detail_item">
                                <span><i className="fa-sharp fa-light fa-pencil"></i> Message</span>
                                <span className="">{data.message}</span>
                            </div>
                            {data.serviceType === SERVICE_TYPE.DISPOSAL && <div className="order-detail_item">
                                <span><i className="fa-regular fa-ballot-check"></i> Itinerary Plan</span>
                                <span className="">{data.itinerary}</span>
                            </div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button data-bs-dismiss="modal" type="button" className="th-btn fw-btn">CLOSE</button>
                    </div>
                </div>
            </div>
        </div></>
}

export default () => {
    const { orders } = useLoaderData() as { orders: IOrder[] }
    const [isLoading, setIsLoading] = useState(false);

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Orders");
    }, [setTitle])

    const fields = [
        {
            label: "Document No", name: "detail", type: "custom", align: "center", control: (item: any, itemIndex: number) => {
                return (<PopupOrderDetail key={itemIndex} {...item} />)
            }
        },
        { label: "Order Date", name: "orderDate", type: "label", align: "center" },
        { label: "Subservice", name: "subserviceType", type: "label", align: "center" },
        { label: "Payment", name: "paymentStatus", type: "label", align: "center" },
        { label: "Status", name: "status", type: "label", align: "center" },
        { label: "Place", name: "place", type: "label", align: "left" },
        { label: "Net", name: "netLabel", type: "label", align: "right" },
    ]

    return (
        <BoxContainer isLoading={isLoading}>
            <DataForm title={{ header: "Orders" }} >
                <div className="row">
                    <div className="col-6">
                        <strong>Number of Orders:</strong> {NumberUtil.convertToMoney(orders.length)} Transactions
                    </div>
                    <div className="col-6">
                        <strong>Sum of Net: </strong> {NumberUtil.convertToMoney(orders.reduce((acc, order) => acc + order.values.net, 0))} Baht
                    </div>
                    <div className="col-12 mt-3">
                        <TableForm fields={fields} values={orders} disabled={true} />
                    </div>
                </div>
            </DataForm>
        </BoxContainer >
    );
};
