import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientAPI } from 'api';
import { IVehicleType } from 'types/vehicle.type';

type SettingType = {
    vehicles: IVehicleType[],
    loadingStatus: string,
    counter: {
        viewing: number;
        booking: number;
        satisfies: number;
    }
}

const initialState: SettingType = {
    vehicles: [],
    loadingStatus: "idle",
    counter: {
        viewing: 0,
        booking: 0,
        satisfies: 0
    }
}

export const fetchInitialSettingData = createAsyncThunk('setting/fetchInitialSettingData', async () => {
    const data = await ClientAPI.getInitialData();

    return data;
});

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        // setSettingData: (state, action: PayloadAction<any>) => {
        //     state.vehicles = action.payload.vehicles;
        //     state.counter = action.payload.counter;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInitialSettingData.pending, (state) => {
                state.loadingStatus = 'loading';
            })
            .addCase(fetchInitialSettingData.fulfilled, (state, action: any) => {
                state.loadingStatus = 'succeeded';
                state.vehicles = action.payload.vehicles;
                state.counter = action.payload.counter;
            })
            .addCase(fetchInitialSettingData.rejected, (state, action) => {
                state.loadingStatus = 'failed';
                console.error("Error => ", action.error.message);
            });
    },
});

// export const { setSettingData } = settingSlice.actions;

export default settingSlice.reducer;