import React, { useEffect, useRef } from 'react';
import './TimerPicker.css';
import { InputFieldPropType } from 'types/inputField.type';
const $ = window.$;

export default (props: InputFieldPropType) => {
    const clockpicker = useRef<HTMLDivElement>(null);
    const timepicker = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!props.disabled)
            $(clockpicker.current).clockpicker({
                afterDone: () => {
                    let input = $(clockpicker.current).find('input:first');
                    let value = input.val();
                    input.val(value);
                }
            });

        $(timepicker.current).change(function (e: React.ChangeEvent<HTMLInputElement>) {
            if (props.onChange) props.onChange({ value: e.target.value, name: props.name });
        });
    }, [])

    // if (props.value !== $(timepicker.current).val()) {
    //     $(timepicker.current).val(props.value).trigger('change');
    // }

    return <div className={`clockpicker form-group col-sm-${props.col ?? 6}`} data-autoclose="true" ref={clockpicker}>
        <input type="text"
            className="form-control timepicker"
            ref={timepicker}
            name={props.name}
            id={props.name}
            defaultValue={props.value || ''}
            autoComplete="off"
            placeholder={props.placeholder || "Format Time 00:00"}
            disabled={props.disabled}
        />
        <i className="fal fa-clock"></i>
    </div >


}
// import React, { useEffect, useRef } from "react"
// import { InputFieldPropType } from "types/inputField.type";
// const $ = window.$;

// export default (props: InputFieldPropType) => {
//     const input = useRef(null);

//     const onValueChanged = (value: any) => {
//         if (props.onChange && props.onChange instanceof Function) props.onChange({ value: value, name: props.name })
//     }

//     useEffect(() => {
//         $(input.current).datetimepicker({ datepicker: !1, format: "H:i", step: 30, onChangeDateTime: onValueChanged });
//     }, []);

//     const hours = [];
//     for (let i = 0; i < 24; i++) {
//         const hour = i.toString().padStart(2, "0");
//         hours.push(`${hour}:00`);
//         hours.push(`${hour}:30`);
//     }

//     return (<div className={`form-group col-sm-${props.col ?? 6}`}>
//         <select name={props.name} id="{props.name}" className="form-control">
//             {hours.map(hour => <option value={hour}>{hour}</option>)}
//         </select>
//         {/* <input
//             ref={input}
//             type="text"
//             className="time-pick form-control"
//             name={props.name}
//             id={props.name}
//             placeholder={props.placeholder || "Select Time"}
//             onChange={onValueChanged}
//             defaultValue={props.value ?? ""}
//             disabled={props.disabled === undefined ? false : props.disabled}
//         /> */}
//         <i className="fal fa-clock"></i>
//     </div>)
// }