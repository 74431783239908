import React, { useContext, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AuthUtil } from "@standard/utils";
import { useForm } from 'react-hook-form';
import DataForm from "components/DataForm";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import bookingSchema from "./booking.schema";
import { useDispatch, useSelector } from "react-redux";
import { getBookingInfo } from "features/booking/bookingSelectors";
import { setBookingInfo } from "features/booking/bookingSlice";
import { IBookingItem } from "types/booking.type";
import { SERVICE_TYPE } from "data/constants";

export const loader = (params: any, bookingInfo: IBookingItem) => {
  if (!bookingInfo.serviceType || !bookingInfo.subserviceType) return redirect("/services?flag=booking");

  return {};
}

export default () => {
  const [errors, setErrors] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState<Boolean>(false);
  const bookingInfo = useSelector(getBookingInfo);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = AuthUtil.getCurrentUser() ?? {
    telephone: "",
    name: "",
    email: "",
    weChatId: "",
    whatAppId: "",
    lineId: "",
  };

  const defaultValues = {
    telephone: bookingInfo.telephone || user.telephone || "",
    name: bookingInfo.name || user.name || "",
    email: bookingInfo.email || user.email || "",
    weChatId: bookingInfo.weChatId || user.weChatId || "",
    whatAppId: bookingInfo.whatAppId || user.whatAppId || "",
    lineId: bookingInfo.lineId || user.lineId || "",
    telephoneCountry: "",
    flightNo: bookingInfo.flightNo || "",
    hours: bookingInfo.hours || "",
    numberOfPassenger: bookingInfo.numberOfPassenger || "",
    numberOfluggage: bookingInfo.numberOfluggage || "",
    pickupDate: bookingInfo.pickupDate || "",
    pickupTime: bookingInfo.pickupTime || "",
    itinerary: bookingInfo.itinerary || "",
    serviceType: bookingInfo.serviceType!,
    subserviceType: bookingInfo.subserviceType!,
    maxPassengers: bookingInfo.vehicle!.maxPassengers,
    luggageAllowance: bookingInfo.vehicle!.luggageAllowance,
  };

  const reactHookForm = useForm({ defaultValues, resolver: joiResolver(bookingSchema) });
  const { setTitle } = useContext(ThemeContext);

  useEffect(() => {
    setTitle("Booking");
  }, [setTitle])

  const fields = [
    { name: "name", label: "Your Name", type: "text", col: 6, icon: <i className="fal fa-user" /> },
    { name: "telephone", label: "Phone Number", type: "telephone", col: 6, icon: <i className="fal fa-phone" /> },
    { name: "email", label: "Email", type: "email", col: 12, icon: <i className="fal fa-envelope" /> },
    bookingInfo.serviceType === SERVICE_TYPE.LIMOUSINE ? { name: "flightNo", label: "Flight Number", type: "text", col: 12, icon: <i className="fal fa-plane" /> } : null,
    bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL ? { name: "hours", label: "Number of hours", type: "number", col: 12, icon: <i className="fal fa-clock" /> } : null,
    { name: "weChatId", label: "We Chat", type: "text", col: 12, icon: <i className="fa-brands fa-weixin" /> },
    { name: "whatAppId", label: "What App", type: "text", col: 12, icon: <i className="fa-brands fa-whatsapp" /> },
    { name: "lineId", label: "Line", type: "text", col: 12, icon: <i className="fa-brands fa-line" /> },
    { name: "numberOfPassenger", label: "Number of passenger", type: "number", col: 12, icon: <i className="fal fa-user" /> },
    { name: "numberOfluggage", label: "Number of luggage", type: "number", col: 12, icon: <i className="fal fa-cart-flatbed-suitcase" /> },
    { name: "pickupDate", label: "Service Date", type: "date", col: 6 },
    { name: "pickupTime", label: "Service Time", type: "time", col: 6 },
    { name: "message", label: "Write a Message.... (Optional)", type: "textarea", col: 12, icon: <i className="fa-sharp fa-light fa-pencil" /> },
    bookingInfo.serviceType === SERVICE_TYPE.DISPOSAL ? { name: "itinerary", label: "Itinerary Plan", type: "textarea", col: 12, icon: <i className="fa-regular fa-ballot-check" /> } : null,
  ].filter(item => item !== null);


  const onSubmit = (inputData: any) => {
    const bookingForm: IBookingItem = {
      ...bookingInfo,
      name: inputData.name ?? "",
      telephone: inputData.telephone ?? "",
      email: inputData.email ?? "",
      flightNo: inputData.flightNo ?? "",
      weChatId: inputData.weChatId ?? "",
      whatAppId: inputData.whatAppId ?? "",
      lineId: inputData.lineId ?? "",
      numberOfluggage: inputData.numberOfluggage ?? "",
      numberOfPassenger: inputData.numberOfPassenger ?? "",
      message: inputData.message ?? "",
      pickupDate: inputData.pickupDate ?? null,
      pickupTime: inputData.pickupTime ?? "",
      itinerary: inputData.itinerary ?? "",
      hours: inputData.hours ?? "",
    }

    dispatch(setBookingInfo(bookingForm));

    setRedirect(true);
  }

  useEffect(() => {
    if (redirect === true) return navigate(`/checkout`);
  }, [redirect]);


  const buttons = [
    <button type="submit" className="th-btn fw-btn" key='submit' name='bookNow' >
      Book Now <i className="fa-regular fa-arrow-right" > </i>
    </button>
  ]

  return (
    <BoxContainer isLoading={isLoading}>
      <DataForm
        title={{
          header: "Make Your Booking Today",
          body: `It is a long established fact that a reader will be distracted by
          the readable content of a page when looking at its layout.The
          point of using is that it has distribution of letters to using
          content here making it look like readable.`,
        }}
        onSubmit={onSubmit}
        fields={fields}
        refForm={reactHookForm}
        buttons={buttons}
        errors={errors}
      >
      </DataForm>
    </BoxContainer>
  );
};