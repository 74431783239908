import { SERVICE_TYPE } from "data/constants";
import Joi from "joi";

export default Joi.object({
  startDestination: Joi.string().required().label("Pickup"),
  serviceType: Joi.any(),
  endDestination: Joi.string()
    .label("Drop off")
    .when("serviceType", {
      is: SERVICE_TYPE.LIMOUSINE,
      then: Joi.required(),
      otherwise: Joi.optional().allow(""),
    }),
})
  .custom((value, helpers) => {
    if (
      !value.distance ||
      value.distance === null ||
      value.distance === "" ||
      value.distance === undefined ||
      value.distance === 0
    ) {
      return helpers.message({
        custom: `Distance is invalid, please click the "Clear" button first and try again`,
      });
    }

    return value;
  })
  .options({ abortEarly: false, allowUnknown: true });
