import { MemberAPI } from "api";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil, AuthUtil, NumberUtil } from "@standard/utils";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import { IUserProfile } from "types/userProfile.type";
import { IChangePassword } from "types/changePassword.type";
import Joi from "joi";

const defaultValues: IChangePassword = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
}

export default () => {
    const { profile } = useLoaderData() as { profile: IUserProfile };
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const changePasswordSchema = useMemo(() => {
        const schema: any = {
            newPassword: Joi.string().required().min(8).label('New Password'),
            confirmPassword: Joi.required().equal(Joi.ref('newPassword')).label('Confirm password').options({ messages: { 'any.only': '{{#label}} does not match with New Password' } })
        }

        if (profile.hasPassword === true) schema.oldPassword = Joi.string().required().label('Old Password')

        return Joi.object(schema).options({ abortEarly: false, allowUnknown: true })
    }, [profile])

    const reactHookForm = useForm({
        defaultValues, resolver: joiResolver(changePasswordSchema)
    });

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Change Password");
    }, [setTitle])

    const fields = [
        { name: "oldPassword", label: "Old Password", type: "password", col: 12, icon: <i className="fal fa-key" />, disabled: profile.hasPassword === false },
        { name: "newPassword", label: "New Password", type: "password", col: 12, icon: <i className="fal fa-key" /> },
        { name: "confirmPassword", label: "Confirm Password", type: "password", col: 12, icon: <i className="fal fa-key" /> },
    ];

    const onSubmit = (inputData: IChangePassword) => {
        AlertUtil.confirm("Do you want to change your password ?").then(async (res: boolean) => {
            if (res === false) return;

            setIsLoading(true);
            MemberAPI.changePassword(inputData).then((userInfo: any) => {
                setIsLoading(false);
                AlertUtil.done("Your password is changed").then(() => {
                    AuthUtil.setCurrentUser(userInfo);
                });
            }).catch((apiError) => {
                setIsLoading(false);
                setErrors(apiError);
            });
        });
    }

    const buttons = [
        <button type="submit" className="th-btn fw-btn" >
            CHANGE PASSWORD <i className="fa-regular fa-arrow-right"></i>
        </button>
    ]

    return (
        <BoxContainer isLoading={isLoading}>
            <DataForm
                onSubmit={onSubmit}
                fields={fields}
                refForm={reactHookForm}
                buttons={buttons}
                errors={errors}
            >
            </DataForm>
        </BoxContainer>
    );
};
