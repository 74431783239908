import axios from "@standard/axios";

const login = (username, password, remember) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post("client/member/auth/login", { username, password, remember })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err.message));
  });

  return promise;
};

const loginSocial = (params) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post("client/member/auth/login/social", params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err.message));
  });

  return promise;
};

const logout = () => axios.post(`client/member/auth/logout`);

const changePasswordFromResetPassword = ({ token, newPassword }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`client/member/auth/changepasswordfromresetpassword/${token}`, {
        newPassword: newPassword,
      })
      .then((res) => resolve({ data: res.data }))
      .catch((err) => reject(err.message));
  });

const forgetPassword = async (email) =>
  new Promise((resolve, reject) => {
    axios
      .post("client/member/auth/forgetPassword", { email })
      .then((res) => {
        if (res.status === 200) resolve({});
      })
      .catch((err) => reject(err.message));
  });

const changePassword = ({ oldPassword, newPassword, confirmPassword }) =>
  new Promise((resolve, reject) => {
    axios
      .put(`client/member/auth/changepassword`, {
        oldPassword,
        newPassword,
        confirmPassword,
      })
      .then((res) => resolve({ data: res.data }))
      .catch((err) => reject(err.message));
  });


export default {
  login,
  loginSocial,
  logout,
  changePasswordFromResetPassword,
  forgetPassword,
  changePassword
}