import { HelperUtil } from '@standard/utils';
import { ClientAPI } from 'api';
import PopupImages from 'components/PopupImages';
import { ThemeContext } from 'components/Root';
import { getBookingInfo } from 'features/booking/bookingSelectors';
import { selectVehicle } from 'features/booking/bookingSlice';
import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { IBookingItem } from 'types/booking.type';
import { IVehicleType } from 'types/vehicle.type';
const $ = window.$;

export async function loader({ params }: any) {
  const vehicle = await ClientAPI.findOneVehicle(params.vehicleId).catch((err) => {
    throw err
  });

  return { vehicle };
}

export default () => {
  const { vehicle } = useLoaderData() as { vehicle: IVehicleType };
  const { setTitle } = useContext(ThemeContext);
  const navigate = useNavigate();
  const bookingInfo: IBookingItem = useSelector(getBookingInfo);
  const dispatch = useDispatch();
  const [vehicleId, setVehicleId] = useState<string | undefined>();

  useEffect(() => {
    setTitle(vehicle.name);
  }, [setTitle])

  useEffect(() => {
    if (vehicleId) {
      if (bookingInfo.serviceType) return navigate(`/booking`);

      return navigate(`/services?flag=booking`);
    }
  }, [vehicleId])

  const onBookThisCarClicked = () => {
    dispatch(selectVehicle(vehicle));
    setVehicleId(vehicle._id);
  }


  return (
    <>
      <PopupImages values={vehicle.imageAssetsURL} />
      <div className="container">
        <div className='mb-5'>
          {HelperUtil.htmlParse(vehicle.description ?? "")}
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="form-btn col-12">
              <button className="th-btn w-100" onClick={onBookThisCarClicked}>Book This Car Now</button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
