import React, { createContext, ReactElement, Ref, useContext, useEffect, useRef, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Outlet, useLoaderData, useLocation, useNavigation } from 'react-router-dom';
import Loader from '../Loader';
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { HelperUtil } from '@standard/utils';

declare global {
    interface Window {
        $: any;
        google: any;
    }
}

const $ = window.$;

interface ThemeContextType {
    setTitle: (title: string) => void;
}

// Provide a default value that won't cause errors
const defaultThemeContext: ThemeContextType = {
    setTitle: (title: string) => { },
};

export const ThemeContext = createContext<ThemeContextType>(defaultThemeContext);

type Props = {
    children?: any;
}

export default ({ children }: Props) => {
    const [title, setTitle] = useState<string>("");
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigation = useNavigation();

    const themeContextValue: ThemeContextType = {
        setTitle: (title: string) => {
            document.title = `RV - ${title}`;
            setTitle(title);
        }
    }

    return (
        <ThemeContext.Provider value={themeContextValue}>
            <div className="App">
                <Header title={title} />
                <div style={{ "position": "relative" }}>
                    <SwitchTransition>
                        <CSSTransition
                            key={location.pathname}
                            timeout={200}
                            classNames="page"
                            unmountOnExit
                        >
                            {(state) => (
                                <Loader isLoading={isLoading || navigation.state === "loading"}>
                                    <Outlet />{children}
                                </Loader>
                            )}
                        </CSSTransition>
                    </SwitchTransition>
                </div>
                <Footer />
            </div>
        </ThemeContext.Provider>
    )
}
