import React, {
  memo,
} from "react";
import FormInput from "../Input";
import { Controller, useForm } from "react-hook-form";

const DataFormContent = ({ fields, refForm }: any) => {
  const getField = (field: any, index: number) => {
    if (field.type === "custom") return field.control;

    const rules = {
      required: field.required ? `${field.label} is required` : false,
    };

    if (field.validate) {
      // rules.validate = field.validate;
    }

    return (
      <Controller
        key={field.name}
        control={refForm.control}
        name={field.name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormInput
            field={field}
            value={value}
            onChange={({ value }: any) => onChange(value)}
          />
        )}
      />
    );
  };

  return (
    <>
      {fields && <div className="row">{fields.map(getField)}</div>}
    </>
  );
};

export default memo(DataFormContent);
