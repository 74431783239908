import { ThemeContext } from 'components/Root';
import React, { useContext, useEffect } from 'react'

export default () => {
    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("How To Book");
    }, []);

    return (
        <section className="position-relative overflow-hidden space-bottom" style={{ "marginTop": "70px" }}>
            <div className="container">
                <div className="title-area text-center">
                    <span className="sub-title style2"><img src="/theme/assets/img/shape/title_shape_1.svg" alt="shape" /><span className="sub-title2">Our Working Process</span></span>
                    <h2 className="sec-title">How to Book</h2>
                </div>
                <div className="process-box-wrapper style2">
                    <div className="row gy-30 justify-content-center">
                        <div className="col-md-6 col-lg-4">
                            <div className="process-item wow fadeInLeft" data-bg-src="/theme/assets/img/shape/process_shape.png">
                                <div className="process-item_icon">
                                    <img src="/theme/assets/img/icon/process_1_1.svg" alt="" />
                                </div>
                                <p className="process-item_text">Send the service information to e-mail : reservations@rvlimousine<br />service.com<br />
                                    Line ID : @rvlimousine</p>
                                <span className="process-item_num">01</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="process-item wow fadeInUp" data-bg-src="/theme/assets/img/shape/process_shape.png">
                                <div className="process-item_icon">
                                    <img src="/theme/assets/img/icon/process_1_2.svg" alt="" />
                                </div>
                                <p className="process-item_text">Reservation received the information -&gt; make a booking on system -&gt;
                                    reply back to confirmation the customer</p>
                                <span className="process-item_num">02</span>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="process-item wow fadeInRight" data-bg-src="/theme/assets/img/shape/process_shape.png">
                                <div className="process-item_icon">
                                    <img src="/theme/assets/img/icon/process_1_3.svg" alt="" />
                                </div>
                                <p className="process-item_text">The customer recheck the accuracy of the detail
                                    - send the booking confirmation to the guest.</p>
                                <span className="process-item_num">03</span>
                            </div>
                        </div>
                        <span className="process-line">
                            <img src="/theme/assets/img/shape/process_line_3.png" alt="line" />
                        </span>

                    </div>
                </div>
            </div>

        </section>
    )
}
