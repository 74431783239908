import { AlertUtil, AuthUtil, CookieUtil } from "@standard/utils";
import { ClientAPI } from "api";
import { ThemeContext } from "components/Root";
import { getBookingInfo } from "features/booking/bookingSelectors";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IVehicleType } from "types/vehicle.type";
import PaymentForm from "./PaymentForm";
import { IOrder } from "types/order.type";
import ContactInfo from "./ContactInfo";
import { store } from "store";
import { clearBooking } from "features/booking/bookingSlice";
import { IBookingItem } from "types/booking.type";
import { Link, redirect } from "react-router-dom";
import OrderList from "./OrderList";
import BoxContainer from "components/BoxContainer";
import { ITotal } from "types/total.type";
import { SERVICE_TYPE, SUBSERVICE_TYPE } from "data/constants";
const $ = window.$;

export const loader = (params: any, bookingInfo: IBookingItem) => {
  if (!bookingInfo.serviceType || !bookingInfo.subserviceType)
    return redirect("/services?flag=booking");

  return {};
};

export const LinkToEditService = ({
  bookingInfo,
}: {
  bookingInfo: IBookingItem;
}) => {
  switch (bookingInfo.subserviceType) {
    case SUBSERVICE_TYPE.LIMOUSINE_TRANSFER:
      return (
        <Link
          to={`/services/${SERVICE_TYPE.LIMOUSINE}/${SUBSERVICE_TYPE.LIMOUSINE_TRANSFER}`}
          className="ms-2"
        >
          <i className="fa fa-pencil"></i> Edit Place
        </Link>
      );
    case SUBSERVICE_TYPE.LIMOUSINE_POINT_TO_POINT:
      return (
        <Link
          to={`/services/${SERVICE_TYPE.LIMOUSINE}/${SUBSERVICE_TYPE.LIMOUSINE_POINT_TO_POINT}`}
          className="ms-2"
        >
          <i className="fa fa-pencil"></i> Edit Place
        </Link>
      );
    case SUBSERVICE_TYPE.DISPOSAL_OUTSIDE:
      return (
        <Link
          to={`/services/${SERVICE_TYPE.DISPOSAL}/${SUBSERVICE_TYPE.DISPOSAL_OUTSIDE}`}
          className="ms-2"
        >
          <i className="fa fa-pencil"></i> Edit Place
        </Link>
      );
    case SUBSERVICE_TYPE.DISPOSAL_WITHIN:
      return (
        <Link
          to={`/services/${SERVICE_TYPE.DISPOSAL}/${SUBSERVICE_TYPE.DISPOSAL_WITHIN}`}
          className="ms-2"
        >
          <i className="fa fa-pencil"></i> Edit Place
        </Link>
      );
    default:
      return null;
  }
};

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState<ITotal>({
    total: 0,
    discount: null,
    net: 0,
  });
  const bookingInfo = useSelector(getBookingInfo);
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");

  const { setTitle } = useContext(ThemeContext);
  useEffect(() => {
    setTitle("Checkout");
  }, []);

  useEffect(() => {
    if (bookingInfo) {
      ClientAPI.getTotal(bookingInfo).then((data: ITotal) => {
        setSummary(data);
        setIsLoading(false);
      });
    }
  }, []);

  const onOrderConfirmed = async () => {
    setIsLoading(true);

    return new Promise<IOrder>((resolve) => {
      const memberToken = AuthUtil.isAuth() ? CookieUtil.getToken() : null;

      ClientAPI.order({ ...bookingInfo, memberToken, couponCode })
        .then((order) => {
          resolve(order as IOrder);
          dispatch(clearBooking());
        })
        .catch((err) => {
          setIsLoading(false);
          AlertUtil.error(err ?? "Something wrong, please try again !!!");
        });
    });
  };

  const handleApplyCouponClick = () => {
    setIsLoading(true);
    ClientAPI.getTotal(bookingInfo, couponCode)
      .then((data: ITotal) => {
        setSummary(data);
        setIsLoading(false);
      })
      .catch((errorMessage) => {
        setIsLoading(false);
        AlertUtil.error(errorMessage);
      });
  };

  return (
    <BoxContainer isLoading={isLoading}>
      <div className="row">
        <ContactInfo bookingInfo={bookingInfo} />
        <OrderList bookingInfo={bookingInfo} summary={summary} />
        <div className="input-group mb-3">
          <input
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") handleApplyCouponClick();
            }}
            className="form-control"
            value={couponCode}
            placeholder="Coupon code"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCouponCode(e.target.value)
            }
          />
          <button
            onClick={handleApplyCouponClick}
            className="th-btn fw-btn"
            style={{ width: "auto" }}
            type="button"
          >
            Apply <i className="fa-regular fa-tags"></i>
          </button>
        </div>
        <div className="mt-lg-3 mb-30 col-sm-12">
          <PaymentForm onOrderConfirmed={onOrderConfirmed} />
        </div>
      </div>
    </BoxContainer>
  );
};
