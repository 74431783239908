import { AlertUtil } from "@standard/utils";
import React, { useEffect, useState, memo } from "react";
import "./tableForm.css";

const FormInput = ({ field, value, onChange }: any) => {
  switch (field.type) {
    case "label":
      return value

  }
  // field={{
  //   ...field,
  //   label: "",
  //   prefix: prefix,
  //   postfix: postfix,
  //   datasource,
  // }}
  // value={currentValue}
  // onChange={({ name, value }) => {
  //   const newItems = [...values];
  //   newItems[itemIndex].values[name] = value;
  //   onChange(newItems);
  // }}
}
interface FieldInterface {
  key: any;
  values: {
    [key: string]: any
  }
}

const TableForm = ({
  fields = [], // { name: '', label: '', value: '', type: '', defaultValue: '', 'align' }
  label = "",
  remark = "",
  name = "",
  values = [], // { key: "", values: {}, removable: false }
  disabled = false,
  enableInsert = true,
  enableTrash = true,
  onChange = {},
  buttons = [],
  summaryFields = [],// [{ label: "", value: "" }]
  children = null,
  enableCheckbox = false,
  onSelected = null,
}: any) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState([]);

  const getDefaultItem = () => {
    var defaultItem: FieldInterface = {
      key: null,
      values: {},
    };

    fields.map((item: any) => {
      const defaultValue = item.defaultValue || "";
      defaultItem.values[item.name] = defaultValue;
    });

    return defaultItem;
  };

  const removeItem = (index: number) => {
    onChange(values.filter((f: any, fIndex: number) => fIndex !== index));
  };

  const onSelectItem = (index: number) => {
    const existsItem = selectedItemIndex.includes(index as never);
    if (existsItem === false)
      setSelectedItemIndex([...selectedItemIndex, index as never]);
    else setSelectedItemIndex(selectedItemIndex.filter((f) => f !== index));
  };

  useEffect(() => {
    if (onSelected && onSelected instanceof Function) {
      onSelected(selectedItemIndex);
    }
  }, [JSON.stringify(selectedItemIndex)]);

  var headers = fields.map((item: any, index: number) => { return <th key={index}> {item.label} </th> });

  if (enableCheckbox === true) {
    headers.unshift(
      <th key="checkbox-header" >
        <div className="checkbox" >
          <input
            disabled={disabled}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked === true)
                return setSelectedItemIndex(
                  values.map((value: any, valueIndex: number) => valueIndex)
                );
              return setSelectedItemIndex([]);
            }}
          />
        </div>
      </th>
    );
  }

  var fieldItems = values.map((item: any, itemIndex: number) => {
    const fieldItem = fields.map((field: any, fieldIndex: number) => {
      let alignClass = "text-left";
      let currentValue: any;

      if (field.align) {
        if (field.align === "center") {
          alignClass = "text-center";
        } else if (field.align === "right") {
          alignClass = "text-end";
        }
      }

      if (field.type === "custom")
        return (
          <td key={fieldIndex} className={alignClass} >
            {field.control(item, itemIndex)}
          </td>
        );

      field.disabled = disabled;

      if (item.disabled === true) field.disabled = true;

      let prefix, postfix;

      if (field.prefix) {
        prefix = field.prefix
          .split("]")
          .map((param: any) => item.values[param.replace("[", "")])
          .join("");
      }

      if (field.postfix) {
        if (field.postfix.indexOf("[") === -1) postfix = field.postfix;
        else {
          postfix = field.postfix
            .split("]")
            .map((param: any) => item.values[param.replace("[", "")])
            .join("");
        }
      }

      if (item.values[field.name] !== undefined) {
        currentValue = item.values[field.name];

        if (currentValue instanceof Object) {
          const { value, description } = currentValue;
          if (value !== undefined) {
            field.description = description;
            currentValue = value;
          }
        }
      }

      if (field.placeholder !== undefined) {
        field.placeholder = item[item.placeholder];
      }

      let datasource;
      if (field.datasource instanceof Function) {
        datasource = field.datasource(item);
      } else {
        datasource = field.datasource;
      }

      return (
        <td key={fieldIndex} className={alignClass} >
          <FormInput
            field={{
              ...field,
              label: "",
              prefix: prefix,
              postfix: postfix,
              datasource,
            }}
            value={currentValue}
            onChange={({ name, value }: { name: string, value: any }) => {
              const newItems = [...values];
              newItems[itemIndex].values[name] = value;
              onChange(newItems);
            }}
          />
        </td>
      );
    });

    let trashCol;
    if (disabled === false) {
      if (enableTrash) {
        if (item.removable === true || item.removable === undefined) {
          trashCol = (
            <td className="text-center" >
              <button
                onClick={
                  () => {
                    AlertUtil.confirm("คุณแน่ใจไหมที่จะลบรายการนี้").then(
                      (res: any) => {
                        if (res === true) removeItem(itemIndex);
                      }
                    );
                  }
                }
                type="button"
                data-toggle="tooltip"
                data-placemeent="top"
                data-original-title="Trash"
                title="Trash"
                className="btn btn-light btn-sm ml-1">
                <i className="fa fa-trash" > </i>
              </button>
            </td >
          );
        } else {
          trashCol = <td className="text-center" > </td>;
        }
      }
    }

    let checkboxCol;

    if (enableCheckbox === true) {
      checkboxCol = (
        <td className="text-center" key={"checkbox_" + itemIndex} >
          <div className="checkbox" >
            <input
              type="checkbox"
              onChange={() => onSelectItem(itemIndex)
              }
              checked={selectedItemIndex.includes(itemIndex as never)}
              disabled={disabled}
            />
          </div>
        </td>
      );
    }

    return (
      <tr key={itemIndex} >
        {checkboxCol}
        {fieldItem}
        {trashCol}
      </tr>
    );
  });

  const addNewItem = () => {
    const newItem = getDefaultItem();

    onChange([...values, newItem]);
  };

  let btnInsert;
  let actionColumnHead = null;

  if (disabled === false) {
    if (enableInsert) {
      btnInsert = (
        <button
          onClick={addNewItem}
          type="button"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Add New"
          title="Add New"
          className="btn btn-primary btn-sm ml-1 mb-2 float-right"
        >
          <i className="fa fa-plus" > </i> Add New
        </button >
      );
    }

    if (enableTrash) {
      actionColumnHead = <th># Action </th>;
    }
  }

  return (
    <div className="w-100" >
      {label && <label className="col-form-label" > {label}: </label>}
      {remark && <small className="d-block text-muted" > {remark} </small>}
      {children} {btnInsert} {buttons}
      <table className="table-style" >
        <thead>
          <tr>
            {headers}
            {actionColumnHead}
          </tr>
        </thead>
        < tbody > {fieldItems} </tbody>
        {
          summaryFields && (
            <tfoot>
              {
                summaryFields.map((field: any, fieldIndex: number) => {
                  return (
                    <tr key={fieldIndex} >
                      <td className="text-right" colSpan={headers.length - 1} >
                        <strong>{field.label} </strong>
                      </td>
                      < td className="text-right" > {field.value} </td>
                      {disabled === false && enableTrash === true && <td></td>}
                    </tr>
                  );
                })
              }
            </tfoot>
          )}
      </table>
    </div>
  );
};

export default memo(TableForm);
