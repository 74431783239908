import { getVehicles } from "features/setting/settingSelectors";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const $ = window.$;

const Footer = () => {
  const vehicles = useSelector(getVehicles);

  useEffect(() => {
    if ($(".scroll-top").length > 0) {
      var scrollTopbtn = document.querySelector(".scroll-top");
      var progressPath: any = document.querySelector(".scroll-top path");

      if (!progressPath) return;

      var pathLength = progressPath.getTotalLength();
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "none";
      progressPath.style.strokeDasharray = pathLength + " " + pathLength;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "stroke-dashoffset 10ms linear";
      var updateProgress = function () {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = pathLength - (scroll * pathLength) / height;
        progressPath.style.strokeDashoffset = progress;
      };
      updateProgress();
      $(window).scroll(updateProgress);
      var offset = 50;
      var duration = 750;
      $(window).on("scroll", function () {
        if ($(this).scrollTop() > offset) {
          $(scrollTopbtn).addClass("show");
        } else {
          $(scrollTopbtn).removeClass("show");
        }
      });
      $(scrollTopbtn).on("click", function (event: any) {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          duration
        );
        return false;
      });
    }
  }, []);

  return (
    <>
      <footer className="footer-wrapper footer-layout3">
        <div className="widget-area">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6 col-xl-auto">
                <div className="widget footer-widget">
                  <h3 className="widget_title">About Us</h3>
                  <div className="th-widget-about">
                    <p className="footer-text">
                      Luxury car service with driver Superior to others, safe,
                      worry-free 24 hours a day.
                    </p>
                    <h4 className="footer-info-title">WE ARE AVAILABLE</h4>
                    <p className="footer-text">
                      Reservation (24/7, 365 days for you)
                    </p>
                  </div>
                </div>
                <div className="th-social style2 mb-3">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=61557836698167"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/rv_limousine_service"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">Our Car</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      {vehicles.map((vehicle) => (
                        <li key={vehicle._id}>
                          <Link to={`vehicle/${vehicle._id}`}>
                            {vehicle.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-auto">
                <div className="widget footer-widget">
                  <h4 className="widget_title">Facebook</h4>
                  <div className="facebook-mobile">
                    <a
                      href="https://www.facebook.com/profile.php?id=61557836698167"
                      target="_blank"
                    >
                      RV Transport Facebook
                    </a>
                  </div>
                  <div className="facebook-desktop">
                    <iframe
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61557836698167&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1037127908202047"
                      width="340"
                      height="500"
                      style={{ border: "none", overflow: "hidden" }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <p className="copyright-text">
              © 2024 <Link to="/">RV Limousine Service </Link>
              . All Rights Reserved.
              <br />
              <a target="_blank" href="https://www.facebook.com/deesitedesign/">
                Design By Deesitedesign
              </a>
              &nbsp;<i className="btn-red fa-sharp fa-solid fa-xmark"></i>&nbsp;
              <a target="_blank" href="https://incoze.com/">
                InCoze
              </a>
            </p>
          </div>
        </div>
      </footer>
      <div className="scroll-top">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "307.919",
            }}
          ></path>
        </svg>
      </div>
    </>
  );
};

export default memo(Footer);
