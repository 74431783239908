import React, { ReactNode } from 'react'

interface IDataFormTitleAreaInterface {
    title?: {
        header?: string,
        body?: ReactNode,
    }
}
export default function DataFormTitleArea({ title }: IDataFormTitleAreaInterface) {
    if (!title) return <></>;

    return (
        <div className="booking-title-area">
            {title.header && <h4 className="booking-title">{title.header}</h4>}
            {title.body && <p className="booking-desc">
                {title.body}
            </p>}
        </div>
    )
}
