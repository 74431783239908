import moment, { unitOfTime } from "moment";

export const DATE_FORMAT = {
    FORMAT: "YY/MM/DD",
    FORMAT_WITH_TIME: "YYYY/MM/DD HH:mm",
    FORMAT_TO_SERVER: "YYYY-MM-DD",
    FORMAT_TO_SERVER_WITH_TIME: "YYYY-MM-DD HH:mm:ss",
};


const formatDate = (date: Date): string => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const convertStringToDateTime = (_date: string, format = DATE_FORMAT.FORMAT_WITH_TIME) => {
    if (_date) {
        let date = moment(_date, format).toDate();
        // if (isNaN(date)) {
        //     date = moment(_date, DATE_FORMAT.FORMAT_TO_SERVER_WITH_TIME).toDate();
        // }

        return date;
    }

    return null;
}

function dateDiff(first: Date | String, second: Date | String, type: unitOfTime.Base = "days") {
    // type: years, months, weeks, days, hours, minutes, and seconds
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.

    let firstDate;
    let secondDate;

    if (first instanceof Date) firstDate = moment(first);
    else
        firstDate = moment(
            first.replaceAll("-", "/"),
            DATE_FORMAT.FORMAT_WITH_TIME
        );

    if (second instanceof Date) secondDate = moment(second);
    else
        secondDate = moment(
            second.replaceAll("-", "/"),
            DATE_FORMAT.FORMAT_WITH_TIME
        );

    return secondDate.diff(firstDate, type);
    // const duration = moment.duration(secondDate.diff(firstDate));
    // var hours = duration.asHours();

    // return hours;
}

function convertDateToString(_date: Date, format = DATE_FORMAT.FORMAT) {
    return convertDateTimeToString(_date, format);
}
function convertDateTimeToString(_date: Date, format = DATE_FORMAT.FORMAT_WITH_TIME) {
    if (_date) {
        // let date = moment.utc(_date).format(format);
        let date = moment(_date).format(format);

        return date;
    }

    return null;
}

export default {
    formatDate,
    convertStringToDateTime,
    dateDiff,
    convertDateToString,
    convertDateTimeToString
}