import { ThemeContext } from "components/Root";
import { useContext, useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import React from "react";

const isAPIError = (error: unknown) => {
    if (error instanceof Object && error.hasOwnProperty("status") && error.hasOwnProperty("success") && error.hasOwnProperty("message")) {
        return true;
    }

    return false;
}

export default () => {
    const error: unknown = useRouteError()

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Error !!!");
    }, [setTitle])

    console.log("error", error, isRouteErrorResponse(error), isAPIError(error));

    return (
        <section className="space">
            <div className="container">
                <div className="error-content">
                    <h2 className="error-title"><span className="text-theme">Sorry,</span> an unexpected error has occurred.</h2>
                    <p className="error-text">
                        {(error as Error)?.message ||
                            (error as { statusText?: string })?.statusText}
                    </p>
                    <div className="btn-group justify-content-center">
                        <a href="/" className="th-btn error-btn"><i className="fal fa-home me-2"></i>Back To Home</a>
                    </div>
                </div>
            </div>
        </section>
    )
}