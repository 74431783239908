import { configureStore } from '@reduxjs/toolkit';
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import bookingSlice from 'features/booking/bookingSlice';
import settingSlice from 'features/setting/settingSlice';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import logger from 'middlewares/logger.middleware';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedBookingSlice = persistReducer(persistConfig, bookingSlice)

export const store = configureStore({
    reducer: {
        booking: persistedBookingSlice,
        setting: settingSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
    // .concat(logger),
})

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch