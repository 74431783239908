import { MemberAPI } from "api";
import React, { useContext, useEffect, useState } from "react";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { ThemeContext } from "components/Root";
import { AlertUtil, AuthUtil, NumberUtil } from "@standard/utils";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import profileSchema from "./profile.schema";
import BoxContainer from "components/BoxContainer";
import DataForm from "components/DataForm";
import { IUserProfile } from "types/userProfile.type";

export const loader = async () => {
    try {
        const profile = await MemberAPI.getProfile()

        return { profile }

    } catch (ex: any) {
        if (ex.status === 401) return redirect("/error/401");

        throw ex.message;
    }
}

export default () => {
    const { profile } = useLoaderData() as { profile: IUserProfile };
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const reactHookForm = useForm({
        defaultValues: {
            name: profile.name,
            telephone: profile.telephone,
            email: profile.email,
            weChatId: profile.weChatId,
            whatAppId: profile.whatAppId,
            lineId: profile.lineId,
        }, resolver: joiResolver(profileSchema)
    });
    const [errors, setErrors] = useState("");

    const { setTitle } = useContext(ThemeContext);
    useEffect(() => {
        setTitle("Profile");
    }, [setTitle])

    const fields = [
        { name: "name", label: "Your Name", type: "text", col: 6, icon: <i className="fal fa-user" /> },
        { name: "telephone", label: "Phone Number", type: "telephone", col: 6, icon: <i className="fal fa-phone" /> },
        { name: "email", label: "Email", type: "email", col: 12, icon: <i className="fal fa-envelope" />, disabled: true },
        { name: "weChatId", label: "We Chat", type: "text", col: 12, icon: <i className="fa-brands fa-weixin" /> },
        { name: "whatAppId", label: "What App", type: "text", col: 12, icon: <i className="fa-brands fa-whatsapp" /> },
        { name: "lineId", label: "Line", type: "text", col: 12, icon: <i className="fa-brands fa-line" /> },
    ];

    const onSubmit = (inputData: IUserProfile) => {
        AlertUtil.confirm("Do you want to update your profile ?").then(async (res: boolean) => {
            if (res === false) return;

            setIsLoading(true);
            MemberAPI.updateProfile(inputData).then((userInfo: IUserProfile) => {
                setIsLoading(false);
                AlertUtil.done("Your profile was updated").then(() => {
                    AuthUtil.setCurrentUser(userInfo);
                });
            }).catch((apiError) => {
                setIsLoading(false);
                setErrors(apiError);
            });
        });
    }

    const buttons = [
        <button type="submit" className="th-btn fw-btn" >
            UPDATE NOW <i className="fa-regular fa-arrow-right"></i>
        </button>
    ]

    return (
        <>
            <BoxContainer isLoading={isLoading}>
                <DataForm
                    onSubmit={onSubmit}
                    fields={fields}
                    refForm={reactHookForm}
                    buttons={buttons}
                    errors={errors}
                >
                </DataForm>
            </BoxContainer>
        </>
    );
};
