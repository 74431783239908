import React, { FC, FormEventHandler, memo, ReactNode, useEffect, useMemo, useRef } from "react";
import DataFormFooter from "./DataFormFooter";
import DataFormContent from "./DataFormContent";
import _ from "lodash";
import { UseFormReturn } from "react-hook-form";
import { HelperUtil } from "@standard/utils";
import DataFormErrors from './DataFormErrors';
import DataFormTitleArea from './DataFormTitleArea';

export const DataFormContext = React.createContext({
  buttons: []
});

type onSubmitType = (data: any) => void;

interface IDataFormProps {
  buttons?: any;
  children?: ReactNode;
  fields?: any;
  onSubmit?: onSubmitType;
  refForm?: UseFormReturn<any>;
  errors?: string | string[];
  title?: {
    header?: string,
    body?: ReactNode,
  };
}

const DataForm: FC<IDataFormProps> = ({
  buttons = [],
  children,
  fields = [],
  onSubmit = (data: any) => { },
  refForm,
  errors,
  title,
}) => {
  const form = useRef<HTMLFormElement>(null);
  const onSubmitForm = (data: any) => {
    if (onSubmit) onSubmit(data);
  };

  const contextValues = {
    buttons,
  };

  let formErrors: any[] = [];

  useEffect(() => {
    if (errors && refForm) {
      let errorArray = Array.isArray(errors) ? errors : errors.toString().split(",");

      errorArray.map((errorMessage: string, errorIndex: number) => {
        refForm!.setError(`root.${errorIndex.toString()}`, { type: 'custom', message: errorMessage });
      });
    }
  }, [errors]);

  if (refForm && !HelperUtil.isObjEmpty(refForm.formState.errors)) {
    const {
      formState: { errors },
    } = refForm;

    for (var key in errors) {
      if (key === "root" && Array.isArray(errors[key])) {
        (errors.root as Record<string, any>).map(({ message }: { message: string }) => { formErrors.push(message) });
      } else {
        const { message } = errors[key] as { message: string };
        formErrors.push(message);
      }
    }
  }

  const onErrorForm = () => {
    const element = form.current;
    const positionY = element ? element.offsetTop : 0;

    HelperUtil.goToTop(positionY + 200);
  }

  return (
    <DataFormContext.Provider value={contextValues}>
      <form
        ref={form}
        onSubmit={refForm ? refForm.handleSubmit(onSubmitForm, onErrorForm) : onSubmitForm}
        className="booking-form-area ajax-booking"
      >
        <DataFormTitleArea title={title} />
        <DataFormErrors errors={formErrors} />
        <DataFormContent
          fields={fields}
          refForm={refForm}
        />
        {children}
        <DataFormFooter />
      </form>
    </DataFormContext.Provider >
  );
};

export default DataForm;
