import { ServiceType } from "types/service.type";

type ServiceConstantType = { [key: string]: ServiceType; }
export const SERVICE_TYPE: ServiceConstantType = {
  LIMOUSINE: "limousine",
  DISPOSAL: "disposal",
  AIRPORT: "airport"
}

export const SUBSERVICE_TYPE = {
  LIMOUSINE_TRANSFER: "transfer",
  LIMOUSINE_POINT_TO_POINT: "pointtopoint",
  DISPOSAL_WITHIN: "within",
  DISPOSAL_OUTSIDE: "outside",
};
