import React, { useEffect, useState } from "react"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { InputFieldPropType } from "types/inputField.type";
const $ = window.$;

export default (props: InputFieldPropType) => {
    const [formValue, setFormValue] = useState<string>()
    useEffect(() => {
        if (props.value !== formValue) setFormValue(props.value);
    }, [props.value])

    const onValueChanged = (value: any) => {
        if (props.onChange && props.onChange instanceof Function) props.onChange({ value: value, name: props.name })

        setFormValue(value);
    }

    return (<div className={`form-group col-sm-${props.col ?? 6}`}>
        <PhoneInput
            placeholder={props.placeholder}
            onChange={onValueChanged}
            name={props.name}
            value={formValue}
            defaultCountry="TH"
            disabled={props.disabled === undefined ? false : props.disabled}
        />
        {props.icon ?? ""}
    </div>)
}