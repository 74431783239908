import React, { useEffect } from 'react'
const $ = window.$;

// values = url
export default ({ values }: { values: string[] }) => {
    useEffect(() => {
        // $('.popup-image').magnificPopup({
        //     type: 'image',
        //     gallery: {
        //         enabled: true
        //     }
        // });

        // /* magnificPopup video view */
        // $('.popup-video').magnificPopup({
        //     type: 'iframe'
        // });

        // /* magnificPopup content view */
        // $('.popup-content').magnificPopup({
        //     type: 'inline',
        //     midClick: true,
        // });
    }, []);

    return (
        <div className="overflow-hidden space">
            <div className="container">
                <div className="row gallery-row">
                    {values.map((asset, assetIndex) => <div key={assetIndex} className="col-md-6 col-lg-4 filter-item cat1 cat2 cat5 cat4 cat3">
                        <div className="gallery-card style3">
                            <div className="gallery-img">
                                <img src={asset} alt="" />
                                {/* <a href={asset} className="icon-btn popup-image">
                                    <i className="fa-regular fa-plus"></i>
                                </a> */}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}
